import React, { useContext } from 'react'
import Card from '@app/components/Card'
import styles from './Landing.module.scss'
import Button from '@app/components/Button'
import { UserContext } from '@app/components/Session'
import { Link } from 'react-router-dom'
import ROUTES from '../../../constants/routes'
import BookIcon from './BookIcon'
import { T, t } from '@app/i18n/i18n'

const Landing = () => {
  const user = useContext(UserContext)

  return (
    <div className={styles.wrapper}>
      <Card className={styles.introCard}>
        {(user && !user.emailVerified) ?
          <div style={{color: 'red', marginBottom: '1rem'}}><T path='website.home.pleaseConfirmEmail' /></div>
        : null}
        <h1>Dragonshade App</h1>
        <h2><T path='website.home.subtitle' /></h2>
        <ol className={styles.steps}>
          <li className={styles.step}><T path='website.home.step1' /></li>
          <li className={styles.step}><T path='website.home.step2' /></li>
          <li className={styles.step}><T path='website.home.step3' /></li>
          <li className={styles.step}><T path='website.home.step4' /></li>
        </ol>
        {
          user ?
            (
              user.subscriptionStatus === 'subscribed' ?
                <Link to={ROUTES.DOWNLOAD}><Button><T path='website.home.downloadApp' /></Button></Link>
              :
                <Link to={ROUTES.SUBSCRIBE}><Button><T path='website.home.subscribe' /></Button></Link>
            )
          :
            <Link to={ROUTES.SIGN_IN}><Button><T path='website.home.login' /></Button></Link>
        }
      </Card>
        {[
          'guide',
          'toothReferences',
          'customLibrary',
          'photosVF',
          'virtualFitment',
          'dragonshadeSystem',
          'exactColorAnalysis',
          'patientPhotos',
        ].map(ytVideoId => (
          <Card className={styles.videoCard} key={ytVideoId}>
            <iframe
              width='560'
              height='315'
              src={'https://www.youtube-nocookie.com/embed/' + t('website.home.videos.' + ytVideoId)}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              title='Dragonshade App'
            />
          </Card>
        ))}
      <div className={styles.manualLinks}>
        <BookIcon />
        <a href='https://cdn.shopify.com/s/files/1/0533/3906/2422/files/Bedienungsanleitung_DS.PDF?v=1612642883' target='_blank' rel='noopener noreferrer'>
          <T path='website.home.instructionsDragonshade' />
        </a>
        <a href='https://cdn.shopify.com/s/files/1/0533/3906/2422/files/Bedinungsanleitung_Farbmuster.pdf?v=1612646159' target='_blank' rel='noopener noreferrer'>
          <T path='website.home.instructionsColor' />
        </a>
      </div>
    </div>
  )
}

export default Landing
