import React from 'react'

import styles from './Tabs.module.scss'

interface Props {
  tabs: {
    value: string;
    title: string;
    disabled?: boolean;
  }[],
  currentTab: string;
  onChange: (newTab: string) => void;
  className?: string;
}

const Tabs = ({
  tabs,
  currentTab,
  onChange,
  className
}: Props) => {
  return (
    <div className={[styles.wrapper, className].join(' ')}>
      {tabs.map(tab => (
        <div
          key={tab.value}
          className={[
            styles.tab,
            tab.value === currentTab ? styles.current : undefined,
            tab.disabled ? styles.disabled : undefined
          ].join(' ')}
          onClick={() => !tab.disabled && onChange(tab.value)}
        >{tab.title}</div>
      ))}
    </div>
  )
}

export default Tabs
