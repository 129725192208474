import React, { useState } from 'react'
import BaseModal from '@app/components/Modals/BaseModal'
import Button from '@app/components/Button'
import TextField from '@app/components/TextField'
import Checkbox from '@app/components/Checkbox'
import styles from './AddVoucherModal.module.scss'

const generateVoucherCode = (size = 6) => {
  let code = ''
  const sourceChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789"

  for (let i = 0; i < size; i++) {
    code += sourceChars.charAt(Math.floor(Math.random() * sourceChars.length))
  }

  return code
}

interface Props {
  onRequestClose: () => void;
  onConfirm: (voucher: Voucher) => void;
  allVoucherCodes: string[];
}

const AddVoucherModal: React.FC<Props> = ({
  onRequestClose,
  onConfirm,
  allVoucherCodes
}) => {
  const [code, setCode] = useState('')
  const [monthsValid, setMonthsValid] = useState<number | string>('')
  const [singleUse, setSingleUse] = useState(true)
  const [error, setError] = useState('')

  const handleGenerateCode = () => {
    let newCode: string | undefined = undefined
    while (!newCode || allVoucherCodes.includes(newCode)) {
      newCode = generateVoucherCode()
    }
    setCode(newCode)
  }

  const handleMonthsValidInput = (newValue: string) => {
    if (newValue === '')
      setMonthsValid(newValue)
    if (/^\d+$/.test(newValue))
      setMonthsValid(parseInt(newValue))
  }

  const handleConfirm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!code)
      return setError('Kein Code angegeben')

    if (!monthsValid || typeof monthsValid !== 'number')
      return setError('Keine Gültigkeitsdauer angegeben')

    onConfirm({ code, monthsValid, singleUse, invalidated: false })
  }

  return (<>
    <BaseModal
      onRequestClose={onRequestClose}
      modalClass={styles.addVoucherModal}
      modalCardSize='big'
    >
      <form onSubmit={handleConfirm}>
        <div className={styles.codeInputWrapper}>
          <TextField className={styles.codeInput} label='Gutscheincode' value={code} onChange={setCode}/>
          <Button className={styles.generateCode} onClick={handleGenerateCode} type='button'>Generieren</Button>
        </div>
        <TextField className={styles.monthsValid} label='Gültigkeitsdauer in Monaten' value={monthsValid} onChange={handleMonthsValidInput}/>
        <Checkbox value={singleUse} onChange={setSingleUse}>Einmalige Verwendung</Checkbox>
        <Button className={styles.saveButton} type='submit'>Speichern</Button>
        {error && <p style={{color: 'red'}}>{error}</p>}
      </form>
    </BaseModal>
  </>)
}

export default AddVoucherModal
