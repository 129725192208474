// From https://github.com/stefangabos/world_countries/

export const countriesDe = [
  {
    iso: 'af',
    label: 'Afghanistan',
  },
  {
    iso: 'eg',
    label: 'Ägypten',
  },
  {
    iso: 'al',
    label: 'Albanien',
  },
  {
    iso: 'dz',
    label: 'Algerien',
  },
  {
    iso: 'ad',
    label: 'Andorra',
  },
  {
    iso: 'ao',
    label: 'Angola',
  },
  {
    iso: 'ag',
    label: 'Antigua und Barbuda',
  },
  {
    iso: 'gq',
    label: 'Äquatorialguinea',
  },
  {
    iso: 'ar',
    label: 'Argentinien',
  },
  {
    iso: 'am',
    label: 'Armenien',
  },
  {
    iso: 'az',
    label: 'Aserbaidschan',
  },
  {
    iso: 'et',
    label: 'Äthiopien',
  },
  {
    iso: 'au',
    label: 'Australien',
  },
  {
    iso: 'bs',
    label: 'Bahamas',
  },
  {
    iso: 'bh',
    label: 'Bahrain',
  },
  {
    iso: 'bd',
    label: 'Bangladesch',
  },
  {
    iso: 'bb',
    label: 'Barbados',
  },
  {
    iso: 'by',
    label: 'Belarus',
  },
  {
    iso: 'be',
    label: 'Belgien',
  },
  {
    iso: 'bz',
    label: 'Belize',
  },
  {
    iso: 'bj',
    label: 'Benin',
  },
  {
    iso: 'bt',
    label: 'Bhutan',
  },
  {
    iso: 'bo',
    label: 'Bolivien',
  },
  {
    iso: 'ba',
    label: 'Bosnien und Herzegowina',
  },
  {
    iso: 'bw',
    label: 'Botswana',
  },
  {
    iso: 'br',
    label: 'Brasilien',
  },
  {
    iso: 'bn',
    label: 'Brunei',
  },
  {
    iso: 'bg',
    label: 'Bulgarien',
  },
  {
    iso: 'bf',
    label: 'Burkina Faso',
  },
  {
    iso: 'bi',
    label: 'Burundi',
  },
  {
    iso: 'cl',
    label: 'Chile',
  },
  {
    iso: 'cn',
    label: 'Volksrepublik China',
  },
  {
    iso: 'cr',
    label: 'Costa Rica',
  },
  {
    iso: 'dk',
    label: 'Dänemark',
  },
  {
    iso: 'de',
    label: 'Deutschland',
  },
  {
    iso: 'dm',
    label: 'Dominica',
  },
  {
    iso: 'do',
    label: 'Dominikanische Republik',
  },
  {
    iso: 'dj',
    label: 'Dschibuti',
  },
  {
    iso: 'ec',
    label: 'Ecuador',
  },
  {
    iso: 'ci',
    label: 'Elfenbeinküste',
  },
  {
    iso: 'sv',
    label: 'El Salvador',
  },
  {
    iso: 'er',
    label: 'Eritrea',
  },
  {
    iso: 'ee',
    label: 'Estland',
  },
  {
    iso: 'sz',
    label: 'Eswatini',
  },
  {
    iso: 'fj',
    label: 'Fidschi',
  },
  {
    iso: 'fi',
    label: 'Finnland',
  },
  {
    iso: 'fr',
    label: 'Frankreich',
  },
  {
    iso: 'ga',
    label: 'Gabun',
  },
  {
    iso: 'gm',
    label: 'Gambia',
  },
  {
    iso: 'ge',
    label: 'Georgien',
  },
  {
    iso: 'gh',
    label: 'Ghana',
  },
  {
    iso: 'gd',
    label: 'Grenada',
  },
  {
    iso: 'gr',
    label: 'Griechenland',
  },
  {
    iso: 'gt',
    label: 'Guatemala',
  },
  {
    iso: 'gn',
    label: 'Guinea',
  },
  {
    iso: 'gw',
    label: 'Guinea-Bissau',
  },
  {
    iso: 'gy',
    label: 'Guyana',
  },
  {
    iso: 'ht',
    label: 'Haiti',
  },
  {
    iso: 'hn',
    label: 'Honduras',
  },
  {
    iso: 'in',
    label: 'Indien',
  },
  {
    iso: 'id',
    label: 'Indonesien',
  },
  {
    iso: 'iq',
    label: 'Irak',
  },
  {
    iso: 'ir',
    label: 'Iran',
  },
  {
    iso: 'ie',
    label: 'Irland',
  },
  {
    iso: 'is',
    label: 'Island',
  },
  {
    iso: 'il',
    label: 'Israel',
  },
  {
    iso: 'it',
    label: 'Italien',
  },
  {
    iso: 'jm',
    label: 'Jamaika',
  },
  {
    iso: 'jp',
    label: 'Japan',
  },
  {
    iso: 'ye',
    label: 'Jemen',
  },
  {
    iso: 'jo',
    label: 'Jordanien',
  },
  {
    iso: 'kh',
    label: 'Kambodscha',
  },
  {
    iso: 'cm',
    label: 'Kamerun',
  },
  {
    iso: 'ca',
    label: 'Kanada',
  },
  {
    iso: 'cv',
    label: 'Kap Verde',
  },
  {
    iso: 'kz',
    label: 'Kasachstan',
  },
  {
    iso: 'qa',
    label: 'Katar',
  },
  {
    iso: 'ke',
    label: 'Kenia',
  },
  {
    iso: 'kg',
    label: 'Kirgisistan',
  },
  {
    iso: 'ki',
    label: 'Kiribati',
  },
  {
    iso: 'co',
    label: 'Kolumbien',
  },
  {
    iso: 'km',
    label: 'Komoren',
  },
  {
    iso: 'cd',
    label: 'Kongo, Demokratische Republik',
  },
  {
    iso: 'cg',
    label: 'Kongo, Republik',
  },
  {
    iso: 'kp',
    label: 'Korea, Nord (Nordkorea)',
  },
  {
    iso: 'kr',
    label: 'Korea, Süd (Südkorea)',
  },
  {
    iso: 'hr',
    label: 'Kroatien',
  },
  {
    iso: 'cu',
    label: 'Kuba',
  },
  {
    iso: 'kw',
    label: 'Kuwait',
  },
  {
    iso: 'la',
    label: 'Laos',
  },
  {
    iso: 'ls',
    label: 'Lesotho',
  },
  {
    iso: 'lv',
    label: 'Lettland',
  },
  {
    iso: 'lb',
    label: 'Libanon',
  },
  {
    iso: 'lr',
    label: 'Liberia',
  },
  {
    iso: 'ly',
    label: 'Libyen',
  },
  {
    iso: 'li',
    label: 'Liechtenstein',
  },
  {
    iso: 'lt',
    label: 'Litauen',
  },
  {
    iso: 'lu',
    label: 'Luxemburg',
  },
  {
    iso: 'mg',
    label: 'Madagaskar',
  },
  {
    iso: 'mw',
    label: 'Malawi',
  },
  {
    iso: 'my',
    label: 'Malaysia',
  },
  {
    iso: 'mv',
    label: 'Malediven',
  },
  {
    iso: 'ml',
    label: 'Mali',
  },
  {
    iso: 'mt',
    label: 'Malta',
  },
  {
    iso: 'ma',
    label: 'Marokko',
  },
  {
    iso: 'mh',
    label: 'Marshallinseln',
  },
  {
    iso: 'mr',
    label: 'Mauretanien',
  },
  {
    iso: 'mu',
    label: 'Mauritius',
  },
  {
    iso: 'mx',
    label: 'Mexiko',
  },
  {
    iso: 'fm',
    label: 'Mikronesien',
  },
  {
    iso: 'md',
    label: 'Moldau',
  },
  {
    iso: 'mc',
    label: 'Monaco',
  },
  {
    iso: 'mn',
    label: 'Mongolei',
  },
  {
    iso: 'me',
    label: 'Montenegro',
  },
  {
    iso: 'mz',
    label: 'Mosambik',
  },
  {
    iso: 'mm',
    label: 'Myanmar',
  },
  {
    iso: 'na',
    label: 'Namibia',
  },
  {
    iso: 'nr',
    label: 'Nauru',
  },
  {
    iso: 'np',
    label: 'Nepal',
  },
  {
    iso: 'nz',
    label: 'Neuseeland',
  },
  {
    iso: 'ni',
    label: 'Nicaragua',
  },
  {
    iso: 'nl',
    label: 'Niederlande',
  },
  {
    iso: 'ne',
    label: 'Niger',
  },
  {
    iso: 'ng',
    label: 'Nigeria',
  },
  {
    iso: 'mk',
    label: 'Nordmazedonien',
  },
  {
    iso: 'no',
    label: 'Norwegen',
  },
  {
    iso: 'om',
    label: 'Oman',
  },
  {
    iso: 'at',
    label: 'Österreich',
  },
  {
    iso: 'tl',
    label: 'Osttimor',
  },
  {
    iso: 'pk',
    label: 'Pakistan',
  },
  {
    iso: 'pw',
    label: 'Palau',
  },
  {
    iso: 'pa',
    label: 'Panama',
  },
  {
    iso: 'pg',
    label: 'Papua-Neuguinea',
  },
  {
    iso: 'py',
    label: 'Paraguay',
  },
  {
    iso: 'pe',
    label: 'Peru',
  },
  {
    iso: 'ph',
    label: 'Philippinen',
  },
  {
    iso: 'pl',
    label: 'Polen',
  },
  {
    iso: 'pt',
    label: 'Portugal',
  },
  {
    iso: 'rw',
    label: 'Ruanda',
  },
  {
    iso: 'ro',
    label: 'Rumänien',
  },
  {
    iso: 'ru',
    label: 'Russland',
  },
  {
    iso: 'sb',
    label: 'Salomonen',
  },
  {
    iso: 'zm',
    label: 'Sambia',
  },
  {
    iso: 'ws',
    label: 'Samoa',
  },
  {
    iso: 'sm',
    label: 'San Marino',
  },
  {
    iso: 'st',
    label: 'São Tomé und Príncipe',
  },
  {
    iso: 'sa',
    label: 'Saudi-Arabien',
  },
  {
    iso: 'se',
    label: 'Schweden',
  },
  {
    iso: 'ch',
    label: 'Schweiz',
  },
  {
    iso: 'sn',
    label: 'Senegal',
  },
  {
    iso: 'rs',
    label: 'Serbien',
  },
  {
    iso: 'sc',
    label: 'Seychellen',
  },
  {
    iso: 'sl',
    label: 'Sierra Leone',
  },
  {
    iso: 'zw',
    label: 'Simbabwe',
  },
  {
    iso: 'sg',
    label: 'Singapur',
  },
  {
    iso: 'sk',
    label: 'Slowakei',
  },
  {
    iso: 'si',
    label: 'Slowenien',
  },
  {
    iso: 'so',
    label: 'Somalia',
  },
  {
    iso: 'es',
    label: 'Spanien',
  },
  {
    iso: 'lk',
    label: 'Sri Lanka',
  },
  {
    iso: 'kn',
    label: 'St. Kitts und Nevis',
  },
  {
    iso: 'lc',
    label: 'St. Lucia',
  },
  {
    iso: 'vc',
    label: 'St. Vincent und die Grenadinen',
  },
  {
    iso: 'za',
    label: 'Südafrika',
  },
  {
    iso: 'sd',
    label: 'Sudan',
  },
  {
    iso: 'ss',
    label: 'Südsudan',
  },
  {
    iso: 'sr',
    label: 'Suriname',
  },
  {
    iso: 'sy',
    label: 'Syrien',
  },
  {
    iso: 'tj',
    label: 'Tadschikistan',
  },
  {
    iso: 'tz',
    label: 'Tansania',
  },
  {
    iso: 'th',
    label: 'Thailand',
  },
  {
    iso: 'tg',
    label: 'Togo',
  },
  {
    iso: 'to',
    label: 'Tonga',
  },
  {
    iso: 'tt',
    label: 'Trinidad und Tobago',
  },
  {
    iso: 'td',
    label: 'Tschad',
  },
  {
    iso: 'cz',
    label: 'Tschechien',
  },
  {
    iso: 'tn',
    label: 'Tunesien',
  },
  {
    iso: 'tr',
    label: 'Türkei',
  },
  {
    iso: 'tm',
    label: 'Turkmenistan',
  },
  {
    iso: 'tv',
    label: 'Tuvalu',
  },
  {
    iso: 'ug',
    label: 'Uganda',
  },
  {
    iso: 'ua',
    label: 'Ukraine',
  },
  {
    iso: 'hu',
    label: 'Ungarn',
  },
  {
    iso: 'uy',
    label: 'Uruguay',
  },
  {
    iso: 'uz',
    label: 'Usbekistan',
  },
  {
    iso: 'vu',
    label: 'Vanuatu',
  },
  {
    iso: 've',
    label: 'Venezuela',
  },
  {
    iso: 'ae',
    label: 'Vereinigte Arabische Emirate',
  },
  {
    iso: 'us',
    label: 'Vereinigte Staaten',
  },
  {
    iso: 'gb',
    label: 'Vereinigtes Königreich',
  },
  {
    iso: 'vn',
    label: 'Vietnam',
  },
  {
    iso: 'cf',
    label: 'Zentralafrikanische Republik',
  },
  {
    iso: 'cy',
    label: 'Zypern',
  },
]

export const countriesEn = [
  {
    iso: 'af',
    label: 'Afghanistan',
  },
  {
    iso: 'al',
    label: 'Albania',
  },
  {
    iso: 'dz',
    label: 'Algeria',
  },
  {
    iso: 'ad',
    label: 'Andorra',
  },
  {
    iso: 'ao',
    label: 'Angola',
  },
  {
    iso: 'ag',
    label: 'Antigua and Barbuda',
  },
  {
    iso: 'ar',
    label: 'Argentina',
  },
  {
    iso: 'am',
    label: 'Armenia',
  },
  {
    iso: 'au',
    label: 'Australia',
  },
  {
    iso: 'at',
    label: 'Austria',
  },
  {
    iso: 'az',
    label: 'Azerbaijan',
  },
  {
    iso: 'bs',
    label: 'Bahamas',
  },
  {
    iso: 'bh',
    label: 'Bahrain',
  },
  {
    iso: 'bd',
    label: 'Bangladesh',
  },
  {
    iso: 'bb',
    label: 'Barbados',
  },
  {
    iso: 'by',
    label: 'Belarus',
  },
  {
    iso: 'be',
    label: 'Belgium',
  },
  {
    iso: 'bz',
    label: 'Belize',
  },
  {
    iso: 'bj',
    label: 'Benin',
  },
  {
    iso: 'bt',
    label: 'Bhutan',
  },
  {
    iso: 'bo',
    label: 'Bolivia (Plurinational State of)',
  },
  {
    iso: 'ba',
    label: 'Bosnia and Herzegovina',
  },
  {
    iso: 'bw',
    label: 'Botswana',
  },
  {
    iso: 'br',
    label: 'Brazil',
  },
  {
    iso: 'bn',
    label: 'Brunei Darussalam',
  },
  {
    iso: 'bg',
    label: 'Bulgaria',
  },
  {
    iso: 'bf',
    label: 'Burkina Faso',
  },
  {
    iso: 'bi',
    label: 'Burundi',
  },
  {
    iso: 'cv',
    label: 'Cabo Verde',
  },
  {
    iso: 'kh',
    label: 'Cambodia',
  },
  {
    iso: 'cm',
    label: 'Cameroon',
  },
  {
    iso: 'ca',
    label: 'Canada',
  },
  {
    iso: 'cf',
    label: 'Central African Republic',
  },
  {
    iso: 'td',
    label: 'Chad',
  },
  {
    iso: 'cl',
    label: 'Chile',
  },
  {
    iso: 'cn',
    label: 'China',
  },
  {
    iso: 'co',
    label: 'Colombia',
  },
  {
    iso: 'km',
    label: 'Comoros',
  },
  {
    iso: 'cg',
    label: 'Congo',
  },
  {
    iso: 'cd',
    label: 'Congo, Democratic Republic of the',
  },
  {
    iso: 'cr',
    label: 'Costa Rica',
  },
  {
    iso: 'ci',
    label: "Côte d'Ivoire",
  },
  {
    iso: 'hr',
    label: 'Croatia',
  },
  {
    iso: 'cu',
    label: 'Cuba',
  },
  {
    iso: 'cy',
    label: 'Cyprus',
  },
  {
    iso: 'cz',
    label: 'Czechia',
  },
  {
    iso: 'dk',
    label: 'Denmark',
  },
  {
    iso: 'dj',
    label: 'Djibouti',
  },
  {
    iso: 'dm',
    label: 'Dominica',
  },
  {
    iso: 'do',
    label: 'Dominican Republic',
  },
  {
    iso: 'ec',
    label: 'Ecuador',
  },
  {
    iso: 'eg',
    label: 'Egypt',
  },
  {
    iso: 'sv',
    label: 'El Salvador',
  },
  {
    iso: 'gq',
    label: 'Equatorial Guinea',
  },
  {
    iso: 'er',
    label: 'Eritrea',
  },
  {
    iso: 'ee',
    label: 'Estonia',
  },
  {
    iso: 'sz',
    label: 'Eswatini',
  },
  {
    iso: 'et',
    label: 'Ethiopia',
  },
  {
    iso: 'fj',
    label: 'Fiji',
  },
  {
    iso: 'fi',
    label: 'Finland',
  },
  {
    iso: 'fr',
    label: 'France',
  },
  {
    iso: 'ga',
    label: 'Gabon',
  },
  {
    iso: 'gm',
    label: 'Gambia',
  },
  {
    iso: 'ge',
    label: 'Georgia',
  },
  {
    iso: 'de',
    label: 'Germany',
  },
  {
    iso: 'gh',
    label: 'Ghana',
  },
  {
    iso: 'gr',
    label: 'Greece',
  },
  {
    iso: 'gd',
    label: 'Grenada',
  },
  {
    iso: 'gt',
    label: 'Guatemala',
  },
  {
    iso: 'gn',
    label: 'Guinea',
  },
  {
    iso: 'gw',
    label: 'Guinea-Bissau',
  },
  {
    iso: 'gy',
    label: 'Guyana',
  },
  {
    iso: 'ht',
    label: 'Haiti',
  },
  {
    iso: 'hn',
    label: 'Honduras',
  },
  {
    iso: 'hu',
    label: 'Hungary',
  },
  {
    iso: 'is',
    label: 'Iceland',
  },
  {
    iso: 'in',
    label: 'India',
  },
  {
    iso: 'id',
    label: 'Indonesia',
  },
  {
    iso: 'ir',
    label: 'Iran (Islamic Republic of)',
  },
  {
    iso: 'iq',
    label: 'Iraq',
  },
  {
    iso: 'ie',
    label: 'Ireland',
  },
  {
    iso: 'il',
    label: 'Israel',
  },
  {
    iso: 'it',
    label: 'Italy',
  },
  {
    iso: 'jm',
    label: 'Jamaica',
  },
  {
    iso: 'jp',
    label: 'Japan',
  },
  {
    iso: 'jo',
    label: 'Jordan',
  },
  {
    iso: 'kz',
    label: 'Kazakhstan',
  },
  {
    iso: 'ke',
    label: 'Kenya',
  },
  {
    iso: 'ki',
    label: 'Kiribati',
  },
  {
    iso: 'kp',
    label: "Korea (Democratic People's Republic of)",
  },
  {
    iso: 'kr',
    label: 'Korea, Republic of',
  },
  {
    iso: 'kw',
    label: 'Kuwait',
  },
  {
    iso: 'kg',
    label: 'Kyrgyzstan',
  },
  {
    iso: 'la',
    label: "Lao People's Democratic Republic",
  },
  {
    iso: 'lv',
    label: 'Latvia',
  },
  {
    iso: 'lb',
    label: 'Lebanon',
  },
  {
    iso: 'ls',
    label: 'Lesotho',
  },
  {
    iso: 'lr',
    label: 'Liberia',
  },
  {
    iso: 'ly',
    label: 'Libya',
  },
  {
    iso: 'li',
    label: 'Liechtenstein',
  },
  {
    iso: 'lt',
    label: 'Lithuania',
  },
  {
    iso: 'lu',
    label: 'Luxembourg',
  },
  {
    iso: 'mg',
    label: 'Madagascar',
  },
  {
    iso: 'mw',
    label: 'Malawi',
  },
  {
    iso: 'my',
    label: 'Malaysia',
  },
  {
    iso: 'mv',
    label: 'Maldives',
  },
  {
    iso: 'ml',
    label: 'Mali',
  },
  {
    iso: 'mt',
    label: 'Malta',
  },
  {
    iso: 'mh',
    label: 'Marshall Islands',
  },
  {
    iso: 'mr',
    label: 'Mauritania',
  },
  {
    iso: 'mu',
    label: 'Mauritius',
  },
  {
    iso: 'mx',
    label: 'Mexico',
  },
  {
    iso: 'fm',
    label: 'Micronesia (Federated States of)',
  },
  {
    iso: 'md',
    label: 'Moldova, Republic of',
  },
  {
    iso: 'mc',
    label: 'Monaco',
  },
  {
    iso: 'mn',
    label: 'Mongolia',
  },
  {
    iso: 'me',
    label: 'Montenegro',
  },
  {
    iso: 'ma',
    label: 'Morocco',
  },
  {
    iso: 'mz',
    label: 'Mozambique',
  },
  {
    iso: 'mm',
    label: 'Myanmar',
  },
  {
    iso: 'na',
    label: 'Namibia',
  },
  {
    iso: 'nr',
    label: 'Nauru',
  },
  {
    iso: 'np',
    label: 'Nepal',
  },
  {
    iso: 'nl',
    label: 'Netherlands',
  },
  {
    iso: 'nz',
    label: 'New Zealand',
  },
  {
    iso: 'ni',
    label: 'Nicaragua',
  },
  {
    iso: 'ne',
    label: 'Niger',
  },
  {
    iso: 'ng',
    label: 'Nigeria',
  },
  {
    iso: 'mk',
    label: 'North Macedonia',
  },
  {
    iso: 'no',
    label: 'Norway',
  },
  {
    iso: 'om',
    label: 'Oman',
  },
  {
    iso: 'pk',
    label: 'Pakistan',
  },
  {
    iso: 'pw',
    label: 'Palau',
  },
  {
    iso: 'pa',
    label: 'Panama',
  },
  {
    iso: 'pg',
    label: 'Papua New Guinea',
  },
  {
    iso: 'py',
    label: 'Paraguay',
  },
  {
    iso: 'pe',
    label: 'Peru',
  },
  {
    iso: 'ph',
    label: 'Philippines',
  },
  {
    iso: 'pl',
    label: 'Poland',
  },
  {
    iso: 'pt',
    label: 'Portugal',
  },
  {
    iso: 'qa',
    label: 'Qatar',
  },
  {
    iso: 'ro',
    label: 'Romania',
  },
  {
    iso: 'ru',
    label: 'Russian Federation',
  },
  {
    iso: 'rw',
    label: 'Rwanda',
  },
  {
    iso: 'kn',
    label: 'Saint Kitts and Nevis',
  },
  {
    iso: 'lc',
    label: 'Saint Lucia',
  },
  {
    iso: 'vc',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    iso: 'ws',
    label: 'Samoa',
  },
  {
    iso: 'sm',
    label: 'San Marino',
  },
  {
    iso: 'st',
    label: 'Sao Tome and Principe',
  },
  {
    iso: 'sa',
    label: 'Saudi Arabia',
  },
  {
    iso: 'sn',
    label: 'Senegal',
  },
  {
    iso: 'rs',
    label: 'Serbia',
  },
  {
    iso: 'sc',
    label: 'Seychelles',
  },
  {
    iso: 'sl',
    label: 'Sierra Leone',
  },
  {
    iso: 'sg',
    label: 'Singapore',
  },
  {
    iso: 'sk',
    label: 'Slovakia',
  },
  {
    iso: 'si',
    label: 'Slovenia',
  },
  {
    iso: 'sb',
    label: 'Solomon Islands',
  },
  {
    iso: 'so',
    label: 'Somalia',
  },
  {
    iso: 'za',
    label: 'South Africa',
  },
  {
    iso: 'ss',
    label: 'South Sudan',
  },
  {
    iso: 'es',
    label: 'Spain',
  },
  {
    iso: 'lk',
    label: 'Sri Lanka',
  },
  {
    iso: 'sd',
    label: 'Sudan',
  },
  {
    iso: 'sr',
    label: 'Suriname',
  },
  {
    iso: 'se',
    label: 'Sweden',
  },
  {
    iso: 'ch',
    label: 'Switzerland',
  },
  {
    iso: 'sy',
    label: 'Syrian Arab Republic',
  },
  {
    iso: 'tj',
    label: 'Tajikistan',
  },
  {
    iso: 'tz',
    label: 'Tanzania, United Republic of',
  },
  {
    iso: 'th',
    label: 'Thailand',
  },
  {
    iso: 'tl',
    label: 'Timor-Leste',
  },
  {
    iso: 'tg',
    label: 'Togo',
  },
  {
    iso: 'to',
    label: 'Tonga',
  },
  {
    iso: 'tt',
    label: 'Trinidad and Tobago',
  },
  {
    iso: 'tn',
    label: 'Tunisia',
  },
  {
    iso: 'tr',
    label: 'Türkiye',
  },
  {
    iso: 'tm',
    label: 'Turkmenistan',
  },
  {
    iso: 'tv',
    label: 'Tuvalu',
  },
  {
    iso: 'ug',
    label: 'Uganda',
  },
  {
    iso: 'ua',
    label: 'Ukraine',
  },
  {
    iso: 'ae',
    label: 'United Arab Emirates',
  },
  {
    iso: 'gb',
    label: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    iso: 'us',
    label: 'United States of America',
  },
  {
    iso: 'uy',
    label: 'Uruguay',
  },
  {
    iso: 'uz',
    label: 'Uzbekistan',
  },
  {
    iso: 'vu',
    label: 'Vanuatu',
  },
  {
    iso: 've',
    label: 'Venezuela (Bolivarian Republic of)',
  },
  {
    iso: 'vn',
    label: 'Viet Nam',
  },
  {
    iso: 'ye',
    label: 'Yemen',
  },
  {
    iso: 'zm',
    label: 'Zambia',
  },
  {
    iso: 'zw',
    label: 'Zimbabwe',
  },
]

export const getCountryLabel = (lang: string, iso: string | undefined) => {
  const country = (lang === 'de' ? countriesDe : countriesEn).find((c) => c.iso === iso)
  if (!country) return iso
  return country.label
}
