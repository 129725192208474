import React from 'react'
import Card from '../Card'

import styles from './BaseModal.module.scss'

interface Props {
  onRequestClose: () => void;
  modalClass?: string;
  modalCardSize?: 'small' | 'big';
  hasCloseButton?: boolean;
}

const BaseModal: React.FC<Props> = ({
  onRequestClose,
  modalClass,
  modalCardSize= 'small',
  children,
  hasCloseButton,
}) => {
  return (
    <>
      <div className={styles.modalBackground} onClick={onRequestClose}/>
      <Card className={[modalClass, styles.modal].join(' ')} size={modalCardSize}>
        {hasCloseButton && <button className={styles.closeButton} onClick={onRequestClose}></button>}
        {children}
      </Card>
    </>
  )
}

export default BaseModal
