const getDownloadPath = (platform: 'Windows' | 'macOS', version?: string) => {
  const suffix = {
    macOS: '.dmg',
    Windows: ' Setup.exe',
  }[platform];

  return '/download/Dragonshade App-' + (version || APP_VERSION) + suffix
}

export default getDownloadPath
