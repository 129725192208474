import React, { useContext, useEffect, useState } from 'react'
import marked from 'marked'
import BaseModal from '../BaseModal'
import { FirebaseContext } from '../../Firebase'
import styles from './ReleaseNotesModal.module.scss'
import Spinner from '../../Spinner'
import Button from '../../Button'
import { T } from '../../../i18n/i18n'

interface Props {
  onRequestClose: () => void;
}

const ReleaseNotesModal = ({onRequestClose}: Props) => {
  const firebase = useContext(FirebaseContext)
  const [showAllReleaseNotes, setShowAllReleaseNotes] = useState(false)
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>([])

  useEffect(() => {
    (async () => {
      if (!firebase) return []

      const releaseNotes = await firebase.getReleaseNotes()
      setReleaseNotes(releaseNotes.map(note => ({
        ...note,
        notes: marked(note.notes)
      })))
    })();
  }, [firebase])

  const renderReleaseNote = (note: ReleaseNote, omitVersion = false) => (
    <section key={note.version}>
      {!omitVersion && <strong>{note.version}</strong>}
      <div dangerouslySetInnerHTML={{__html: note.notes}} />
    </section>
  )

  return (
    <BaseModal
      onRequestClose={onRequestClose}
      modalClass={styles.releaseNotesModal}
    >
      {!releaseNotes.length ? <Spinner/> : (<>
        <h1>Dragonshade App {releaseNotes[0].version}</h1>
        <strong>Was gibt es Neues?</strong>
        <div className={styles.content}>
          {renderReleaseNote(releaseNotes[0], true)}
          {showAllReleaseNotes && releaseNotes.slice(1).map(note => renderReleaseNote(note))}
        </div>
        <div className={styles.actionButtons}>
          <Button variant='link' style={{visibility: showAllReleaseNotes ? 'hidden' : 'visible'}} onClick={() => setShowAllReleaseNotes(true)}>Alle Release Notes anzeigen</Button>
          <Button onClick={onRequestClose} className={styles.okButton}><T path='general.ok' /></Button>
        </div>
      </>)}
    </BaseModal>
  )
}

export default ReleaseNotesModal
