import React, { useContext } from 'react'
import { langKeys, langLabels, DEFAULT_LANG } from '../../i18n/i18n'
import Select from '../Select'
import { FirebaseContext } from '../Firebase'
import { UserContext } from '../Session'

const LanguageSelection = () => {
  const user = useContext(UserContext)
  const firebase = useContext(FirebaseContext)

  return (
    <Select
      options={langKeys.map(key => ({
        id: key,
        label: langLabels[key],
      }))}
      value={user?.lang || localStorage.getItem('lang') || DEFAULT_LANG}
      onChange={newLang => {
        if (firebase) {
          firebase.setLang(newLang);
        }

        // If user is not logged in hard reload to refresh language
        if (!user) {
          window.location.reload();
        }
      }}
      compact
    />
  )
}

export default LanguageSelection
