import React, { useContext, useState, useEffect, useRef } from 'react'
import { UserContext } from '@app/components/Session'
import ROUTES from '../../../constants/routes'
import { useHistory } from 'react-router-dom'
import Card from '@app/components/Card'
import Button from '@app/components/Button'
import Spinner from '@app/components/Spinner'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_PUBLIC_KEY_PROD, STRIPE_PLAN_ID_PROD } from '../../../constants/stripe'
import { PAYPAL_PLAN_ID_PROD } from '../../../constants/paypal'
import { FirebaseContext } from '@app/components/Firebase'
import TextField from '@app/components/TextField'
import styles from './Subscribe.module.scss'
import { T, t } from '@app/i18n/i18n'

const renderPrice = (price: number | undefined) => {
  if (typeof price === 'undefined')
    return ''

  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'EUR',
  })

  return formatter.format(price)
}

declare const paypal: any;

const TEMP_MOLLIE_FLAG = true

const Subscribe = () => {
  const user = useContext(UserContext)
  const [stripe, setStripe] = useState<import('@stripe/stripe-js').Stripe | null>(null)
  const [checkoutErrorMsg, setCheckoutErrorMsg] = useState<string | undefined>(undefined)
  const paypalCheckoutButtonWrapper = useRef<HTMLDivElement | null>(null)
  const [initiatedSubscription, setInitiatedSubscription] = useState(false)
  const [voucherCode, setVoucherCode] = useState<string>('')
  const [isCheckingVoucherCode, setIsCheckingVoucherCode] = useState(false)
  const [voucherError, setVoucherError] = useState('')
  const [monthlyCost, setMonthlyCost] = useState<{
    net: number;
    gross: number;
  }>()
  const [userPaysVat, setUserPaysVat] = useState<boolean>()

  const firebase = useContext(FirebaseContext)
  const history = useHistory()

  // Redirect user to home if not logged in or download page if already subscribed
  useEffect(() => {
    if(!user)
      history.push(ROUTES.SIGN_IN)
    else if(user.subscriptionStatus === 'subscribed')
      history.push(ROUTES.DOWNLOAD)
  }, [user, history])

  // Load monthly cost & VAT status
  useEffect(() => {
    firebase?.getMonthlySubscriptionCost().then(cost => setMonthlyCost(cost))
    firebase?.getUserPaysVat().then(r => setUserPaysVat(r.paysVat))
  }, [firebase])

  useEffect(() => {
    (async () => setStripe(await loadStripe(STRIPE_PUBLIC_KEY_PROD)))()
  }, [])

  useEffect(() => {
    if (paypalCheckoutButtonWrapper.current) {
      paypal.Buttons({
        createSubscription: (data: any, actions: any) => {
          return actions.subscription.create({
            plan_id: PAYPAL_PLAN_ID_PROD
          });
        },
        onApprove: (data: any, actions: any) => {
          firebase?.setPayPalSubscriptionID(data.subscriptionID)
          setInitiatedSubscription(true)
        }
      }).render('#paypalCheckoutButtonWrapper');
    }
  }, [firebase, paypalCheckoutButtonWrapper])

  const gotoStripeCheckout = async () => {
    if(stripe) {
      stripe.redirectToCheckout({
        items: [{
          plan: STRIPE_PLAN_ID_PROD,
          quantity: 1,
        }],
        successUrl: window.location.href,
        cancelUrl: window.location.href,
        customerEmail: user?.email || undefined,
      }).then(result => {
        setCheckoutErrorMsg(result.error.message)
      })
    }
  }

  const [creatingSubscription, setCreatingSubscription] = useState(false)
  const [subscriptionError, setSubscriptionError] = useState(false)

  const createMollieSubscription = async () => {
    setCreatingSubscription(true)
    setSubscriptionError(false)
    const result = await firebase?.createMollieSubscription()

    if (!result || !result.success || !result.redirectUrl) {
      setCreatingSubscription(false)
      setSubscriptionError(true)
      return
    }

    window.location.href = result.redirectUrl
  }

  const redeemVoucher = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsCheckingVoucherCode(true)
    setVoucherError('')

    let result: {
      valid: boolean,
      voucher?: Voucher
    } | undefined
    try {
      if (firebase)
        result = await firebase.redeemVoucher({voucherCode})
    } catch (error) {
      return setVoucherError(t('auth.errors.checkingVoucher'))
    } finally {
      setIsCheckingVoucherCode(false)
    }

    if (result?.valid) {
      setVoucherCode('')
    } else {
      setVoucherError(t('auth.errors.invalidVoucher'))
    }
  }

  return (
    <Card className={styles.subscribe}>
      {user?.subscriptionStatus === 'unsubscribed' && !initiatedSubscription && (<>
        <T path='auth.paymentPageText:HTML' />
        {
          (!monthlyCost || typeof userPaysVat === 'undefined') ? (
            <Spinner />
          ) : (
            !userPaysVat ? (
              <T path='auth.subscriptionAmountNet:HTML' params={[renderPrice(monthlyCost.net)]} />
            ) : (
              <T path='auth.subscriptionAmountGross:HTML' params={[renderPrice(monthlyCost.gross), renderPrice(monthlyCost.net)]} />
            )
          )
        }
        {user.lastPaymentStatus === 'failed' && (
          <p className={styles.errorMsg}>
            <T path='auth.errors.paymentError' />
          </p>
        )}
        <div className={styles.paymentMethodsWrapper}>
          {TEMP_MOLLIE_FLAG ? (
              (creatingSubscription || user.lastPaymentStatus === 'pending') ? <Spinner /> : <Button
                onClick={() => createMollieSubscription()}
                className={styles.mollieSubscribeButton}
                disabled={creatingSubscription}
              >
                <T path='auth.subscribe' />
              </Button>
            ) : (
              <>
                <div
                  ref={paypalCheckoutButtonWrapper}
                  id='paypalCheckoutButtonWrapper'
                />
                <Button
                  onClick={gotoStripeCheckout}
                  disabled={!stripe}
                  className={styles.stripeButton}
                ><T path='auth.creditcard' /></Button>
              </>
            )
          }

          <div className={styles.divider}><span><T path='auth.or' /></span></div>

          {isCheckingVoucherCode ? <Spinner /> : (<>
            <form className={styles.voucherInputWrapper} onSubmit={redeemVoucher}>
              <TextField
                value={voucherCode}
                label={t('auth.voucherCode')}
                onChange={setVoucherCode}
                className={styles.voucherInput}
              />
              <Button
                className={styles.voucherConfirm}
                disabled={!voucherCode}
                type='submit'
              ><T path='general.ok' /></Button>
            </form>
            {voucherError && <p style={{color: 'red'}}>{voucherError}</p>}
          </>)}
        </div>
      </>)}
      {(user?.subscriptionStatus === 'subscriptionPending' || initiatedSubscription) && (<>
        <p><T path='auth.paymentBeingProcessed' /></p>
        <Spinner />
        <p><T path='auth.downloadRedirectHint' /></p>
      </>)}
      {checkoutErrorMsg && <p>{checkoutErrorMsg}</p>}
      {subscriptionError && <p className={styles.errorMsg}>{t('auth.errors.subscriptionError')}</p>}
    </Card>
  )
}

export default Subscribe
