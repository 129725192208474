import React, { useContext, useEffect } from 'react'
import { UserContext } from '@app/components/Session'
import { useHistory } from 'react-router-dom'
import Card from '@app/components/Card'
import Button from '@app/components/Button'
import ROUTES from '../../../constants/routes'
import { ReactComponent as AppleLogo } from '../../../assets/icons/apple.svg'
import { ReactComponent as WindowsLogo } from '../../../assets/icons/windows.svg'
import getDownloadPath from 'src/components/GetDownloadPath'
import { T } from '@app/i18n/i18n'
import styles from './Download.module.scss'

const Download = () => {
  const user = useContext(UserContext)

  const history = useHistory()
  // Redirect user to landing page if not subscribed
  useEffect(() => {
    if (user?.subscriptionStatus !== 'subscribed')
      history.push(ROUTES.SUBSCRIBE)
  }, [user, history])

  const downloadApp = (os: 'Windows' | 'macOS') => {
    window.location.href = getDownloadPath(os)
  }

  const os = (() => {
    const { platform, userAgent } = window.navigator
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']

    if (macosPlatforms.indexOf(platform) !== -1) {
      return 'macOS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      return 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'Windows'
    } else if (/Android/.test(userAgent)) {
      return 'Android'
    } else if (/Linux/.test(platform)) {
      return 'Linux'
    }

    return null
  })()

  const macOsButtonProps = {
    onClick: () => downloadApp('macOS'),
    children: <span className={styles.buttonContent}><AppleLogo className={styles.icon} /><T path='auth.downloadForMacOs' /></span>
  }

  const windowsButtonProps = {
    onClick: () => downloadApp('Windows'),
    children: <span className={styles.buttonContent}><WindowsLogo className={styles.icon} /><T path='auth.downloadForWindows' /></span>
  }

  return (
    <Card className={styles.download}>
      <p>
        <strong><T path='auth.downloadPageTitle' /></strong>
      </p>
      {user?.paymentMethod === 'voucher' && <p>
        <T path='auth.voucherValidUntil' params={[user?.voucherValidUntil?.toDate().toLocaleDateString()]}/>
      </p>}

      {
        (os === 'macOS' || os === 'Windows') ?
          (<>
            <Button {...(os === 'macOS' ? macOsButtonProps : windowsButtonProps)} className={styles.primaryButton}/>
            <br/>
            <Button {...(os === 'macOS' ? windowsButtonProps : macOsButtonProps)} className={styles.secondaryButton} variant='link' />
          </>)
        :
          (<>
            <p className={styles.error}>
              <T path='auth.errors.osNotAvailable:HTML' params={[os ? `(${os})` : '']} />
            </p>
            <Button {...windowsButtonProps} className={styles.primaryButton}/>
            <br/>
            <Button {...macOsButtonProps}/>
          </>)
      }
    </Card>
  )
}

export default Download
