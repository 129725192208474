import React from 'react'

import styles from './Spinner.module.scss'

interface Props {
  size?: 'small' | 'big';
}

const Spinner = ({size = 'big'}: Props) => <div className={[styles.spinner, styles[size]].join(' ')} />

export default Spinner
