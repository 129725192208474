import React, { useState, useEffect } from 'react'

import styles from './TextField.module.scss'

interface Props {
  className?: string;
  suffix?: string;
  value: string | number;
  autocomplete?: string;
  label: string;
  onChange: (newValue: string) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  placeholder?: string;
  autofocus?: boolean;
  type?: string;
  max?: number;
  min?: number;
}

const TextField = ({
  className,
  suffix,
  value,
  autocomplete,
  label,
  onChange,
  onClick,
  placeholder,
  autofocus,
  type = 'text',
  max,
  min,
}: Props) => {
  const [hasContent, setHasContent] = useState(false)

  useEffect(() => {
    setHasContent(value !== undefined && value !== null && value !== '')
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(type === 'number') {
      let newValue = parseInt(event.target.value || '0')
      if(max !== undefined)
        newValue = Math.min(newValue, max)
      if(min !== undefined)
        newValue = Math.max(newValue, min)
      onChange(newValue.toString())
    } else {
      onChange(event.target.value)
    }
  }

  return (
    <div className={[styles.wrapper, hasContent ? styles.hasContent : null, className].join(' ')} onClick={onClick}>
      <input
        type={type}
        autoComplete={autocomplete}
        value={value}
        name={label}
        onChange={handleChange}
        placeholder={placeholder}
        autoFocus={autofocus}
      />
      <span style={{marginTop: '.1rem'}}>{suffix}</span>
      <label>{label}</label>
    </div>
  )
}

export default TextField
