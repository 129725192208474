import React from 'react'
import Legal from './Legal'

const AGB = () => {
  return (
    <Legal>
      <h1>Allgemeine Nutzungsbedingungen</h1>
      <h2>Präambel</h2>
      <p>Die App „Dragonshade“ wurde von dem Dental Labor Drachenberg, Köhlershohnerstr. 29, 53578 Windhagen entwickelt und
        bereit gestellt (nachfolgend Anbieter). Der Anbieter ist gleichzeitig Betreiber und technischer Ansprechpartner für
        den Nutzer.&nbsp;</p>
      <p>Diese Nutzungsbedingungen regeln die vertraglichen Abreden zwischen den Nutzern und dem Anbieter. Die App richtet
        sich ausschließlich an Unternehmer im Sinne von § 14 BGB, d. h. jede natürliche oder juristische Person oder
        rechtsfähige Personengesellschaft, die bei Nutzung der App in Ausübung ihrer selbstständigen beruflichen oder
        gewerblichen Tätigkeit handelt. Nutzer im Sinne der nachfolgenden Bedingungen ist demzufolge die natürliche oder
        juristische Person, welche die App in Ausübung ihrer gewerblichen Tätigkeit installiert und nutzt. Der Nutzung im
        Rahmen der Ausübung einer gewerblichen Tätigkeit steht es nicht entgegen, wenn der Nutzer Mitarbeitern oder
        Angestellten im eigenen Unternehmen den Zugang zur App gewährt und diese Personen selbst nicht in Ausübung
        gewerblicher Tätigkeiten handeln.</p>
      <p>Durch das Anklicken der Einverständniserklärung bei Installation erklären die Nutzer, dass sie diese
        Nutzungsbedingungen gelesen und verstanden haben und sie als verbindlich für das Nutzungsverhältnis anerkennen. Die
        Nutzungsbedingungen werden auf dem Webportal des Anbieters angezeigt und können dort jederzeit eingesehen
        werden.&nbsp;</p>
      <h2>1. Allgemeines</h2>
      <p>(1) Diese Nutzungsbedingungen regeln die Richtlinien, unter denen die Benutzung der App erfolgt. Die
        Nutzungsbedingungen finden auch dann Anwendung, wenn die App von außerhalb der Bundesrepublik Deutschland benutzt
        wird. Abweichende Regelungen und insbesondere Bedingungen eines Nutzers, die mit diesen Nutzungsbedingungen in
        Widerspruch stehen, bedürfen zu ihrer Wirksamkeit der ausdrücklichen Zustimmung des Anbieters.</p>
      <p>(2) Der Anbieter behält sich das Recht vor, nach eigenem Ermessen jederzeit und ohne Vorankündigung das Angebot von
        Funktionen, Diensten und Services der App zu erweitern, zu verändern und/oder anzupassen. Eine Reduzierung des
        Nutzungsumfangs oder der bei Vertragsschluss bestehenden Funktionalitäten geht damit nicht einher. Durch Ausfall von
        Systemen, Wartungsarbeiten oder Unterbrechungen in der Datenübertragung kann eine Verzögerung des Downloadvorgangs
        eintreten, wodurch die Installation der App für einen begrenzten Zeitraum verhindert oder zumindest erschwert werden
        kann. Den Nutzern erwachsen aus Änderung, Erweiterung oder Nichtverfügbarkeit des Angebots der App keinerlei
        Ansprüche, sofern nicht ausdrücklich in diesen Nutzungsbedingungen anderes vereinbart ist.</p>
      <h2>2. Vertragsgegenstand</h2>
      <p>(1) Vertragsgegenstand ist die Bereitstellung der App „Dragonshade“ durch den Anbieter zur Nutzung in dem
        nachfolgend beschrieben Umfang:</p>
      <ul>
        <li>Die App „Dragonshade“ stellt eine Hilfestellung für Zahntechniker/Zahnärzte dar. Mit Hilfe der App können
          Zahntechniker/Zahnärzte Farbanalysen vornehmen und somit den möglich Aufbau einer keramischen Schichtung visuell
          darstellen lassen.&nbsp;</li>
        <li>Die App dient darüber hinaus dazu, eine virtuelle Anprobe der Keramikschicht im Umfeld der vorhandenen Zähne
          vorzunehmen.&nbsp;</li>
        <li>Geplant ist eine weitere Funktion, mittels der die App das&nbsp;passende Material für Zahnersatz in
          verschiedenen Dicken und Transparenzen vorschlagen wird.&nbsp;</li>
      </ul>
      <p>(2) Zur Nutzung der App ist es erforderlich, diese einmalig auf dem eigenen Betriebssystem zu installieren. Die
        Installation setzt den Download der App und damit den kostenpflichtigen Erwerb eines Nutzungsrechts voraus. Die
        Nutzungsrechte an der App werden ausschließlich zeitlich beschränkt vergeben. Näheres hierzu regeln die Bestimmungen
        in den Ziffern 4. und 5.</p>
      <p>(3) Bedingung für die einwandfreie Nutzung der App ist, dass der Nutzer über kompatible Geräte und Software,
        insbesondere die kompatible Version des für die App vorgesehenen Betriebssystems, verfügt. Ferner ist zum einmaligen
        Download eine Internetverbindung erforderlich.</p>
      <p>(4) Ein Rechtsanspruch auf Nutzung der App besteht nicht.</p>
      <h2>3. Registrierung</h2>
      <p>(1) Zur erstmaligen Nutzung der App ist die Registrierung als Nutzer und der anschließende Download der App auf ein
        geeignetes Nutzersystem erforderlich.&nbsp;</p>
      <p>(2) Der Erwerb der Nutzungsrechte erfolgt im Wege einer vom Nutzer durchgeführten Bestellung über die Bestellseite
        unter&nbsp;<a href="https://www.dragonshade.app">https://www.dragonshade.app</a>. Als Nutzer kann nur eine Person
        auftreten, welche zum Zeitpunkt des Erwerbs der Nutzungsrechte in Ausübung einer gewerblichen oder freiberuflichen
        Tätigkeit handelt und darüber hinaus das 18. Lebensjahr vollendet hat.&nbsp;</p>
      <p>(3) Nach dem Erwerb der Nutzungsrechte erhält der Nutzer die Möglichkeit, die App per Downloadlink herunter zu
        laden. Der Downloadlink hat eine begrenzte Gültigkeit und kann nur solange verwendet werden, wie eine entsprechende
        Speicherung des Erwerbs der Nutzungsrechte im Browser des Nutzers nachweisbar ist. Löscht der Nutzer die
        Browserdaten, entfällt die Möglichkeit des Downloads.&nbsp;</p>
      <p>(4) Ein Anspruch auf Erwerb des Nutzungsrechts besteht nicht. Der Anbieter ist berechtigt, eine Bestellung und
        damit den Abschluss des Nutzungsvertrages ohne Angaben von Gründen abzulehnen und einen Nutzer auch nach erfolgtem
        Download insbesondere bei schwerwiegenden Verstößen gegen diese Nutzungsbedingungen nach vorheriger Abmahnung von
        der weiteren Nutzung zu sperren.</p>
      <p>(5) Der Anbieter speichert die ihm von den Nutzern übermittelten Nutzerdaten und verpflichtet sich, diese
        vertraulich im Sinne der Datenschutzgrundverordnung und weiterer Datenschutzgesetze zu behandeln. Näheres regelt die
        Datenschutzerklärung.&nbsp;</p>
      <h2>4. Nutzungsberechtigung und Vertragsabschluss</h2>
      <p>(1) Mit der Installation der App erhalten die Nutzer das nicht-ausschließliche (einfache), nicht übertragbares,
        zeitlich auf die Dauer des Vertrages begrenzte Recht, die App sowie die darüber angebotenen Dienste mit sämtlichen
        in diesem Zusammenhang stehenden Inhalten nach Maßgabe dieser Nutzungsbedingungen zu nutzen. Das Nutzungsrecht ist
        nicht auf Dritte übertragbar und es ist den Nutzern nicht gestattet, Dritten entgeltlich oder unentgeltlich
        Nutzungsrechte an der App und/oder den darüber angebotenen Diensten und/oder Inhalten einzuräumen. Den Nutzern ist
        es zudem untersagt, die App oder Teile hiervon zu vervielfältigen, öffentlich zugänglich zu machen und/oder zu
        verbreiten. Hiervon unbenommen bleibt lediglich die Nutzung der App durch eigene Mitarbeiter oder Angestellte des
        Nutzers im selben Unternehmen unter der Voraussetzung, dass die App lediglich auf ein und demselben Endgerät
        betrieben und nicht auf einem Wechseldatenspeicher, einem Intranet oder öffentlichen Netz installiert oder auf
        mehreren Endgeräten genutzt wird. Eine Weitergabe der App an Dritte ist auch bei Löschung der vorhandenen eigenen
        Kopie nicht gestattet.&nbsp;</p>
      <p>(2) Die Registrierung des Nutzers zur Nutzung der App stellt ein verbindliches Angebot an den Anbieter zum
        Abschluss eines Nutzungsvertrages über die Nutzung der App dar. Ein Vertrag kommt jedoch erst zustande, wenn der
        Anbieter die verbindliche Bestellung des Nutzers durch Freischaltung des Zugangs des Nutzers annimmt, oder indem der
        Anbieter dem Nutzer die Annahme in Textform durch eine gesonderte Mitteilung bestätigt.&nbsp;</p>
      <p>(3) Das Nutzungsrecht ist auf die Dauer des jeweiligen Vertrages beschränkt und entfällt nach Beendigung des
        Vertrages gleich welchen Grundes ohne weitere Rechtshandlung. Das Nutzungsrecht ist nicht, auch nicht konzernintern,
        übertragbar.&nbsp;</p>
      <p>(4) Eine über die Regelungen dieser Nutzungsbedingungen hinausgehende Nutzung der App ist nicht gestattet. Der
        Anbieter behält sich vor, Nutzer bei Verstoß gegen diese Nutzungsbedingungen nach vorheriger Abmahnung von der
        Nutzung der App auszuschließen.&nbsp;</p>
      <h2>5. Vertragslaufzeit/Kündigung</h2>
      <p>(1) Das Vertragsverhältnis zwischen dem Anbieter und den Nutzern beginnt mit der Freischaltung des Nutzers durch
        den Anbieter unabhängig von dem Zeitpunkt des Downloads der App. Das Nutzungsrecht wird auf unbestimmte Zeit
        geschlossen. Die Kündigung kann jederzeit zum Ablauf des laufenden Vertragsmonats erfolgen. Eine Rückerstattung
        bereits gezahlter Nutzungsgebühren ist ausgeschlossen.&nbsp;</p>
      <p>(2) Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.&nbsp;</p>
      <p>(3) Im Fall der Beendigung des Nutzungsvertrages ist der Nutzer angehalten, die von ihm auf seinem System
        installierte App sowie sämtliche Kopien hiervon unwiederbringlich zu löschen. Der Nutzer wird weiterhin dafür Sorge
        tragen, dass eine Nutzung der App in seinem Unternehmen durch Dritte nicht erfolgten kann. Gegebenfalls wird der
        Nutzer dem Anbieter gegenüber versichern, dass er alles ihm zumutbare getan hat, um die weitere unbefugte Nutzung
        der App in der von ihm kontrollierten Umgebung zu verhindern. Eine Weiternutzung der App nach Beendigung des
        Nutzungsvertrages stellt eine Urheberrechtsverletzung dar, die vom Anbieter straf- und zivilrechtlich verfolgt
        wird.&nbsp;</p>
      <h2>6. Berechnung und Zahlung</h2>
      <p>(1) Das für die Nutzung der App anfallende Nutzungsentgelt ist jeweils zu Beginn der Vertragslaufzeit für einen
        Monat im Voraus zu entrichten. Beginn der Vertragslaufzeit ist Tag der Freischaltung.&nbsp;</p>
      <p>(2) Im Falle des Zahlungsverzuges behält sich der Anbieter vor, die gesetzlichen Verzugszinsen geltend zu machen
        und den Nutzer vom weiteren Zugang zur App nach vorheriger schriftlicher Androhung auszuschließen. Der Nutzer bleibt
        in einem solchen Fall verpflichtet, die Nutzungsgebühren weiterhin zu entrichten.&nbsp;</p>
      <p>(3) Die Geltendmachung weiterer Schadensersatzansprüche behält sich der Anbieter ausdrücklich vor.&nbsp;</p>
      <h2>7. Datenschutz und Datensicherheit</h2>
      <p>(1) Der sensible und verantwortungsvolle Umgang mit den personenbezogenen Daten der Nutzer ist für den Anbieter von
        großer Wichtigkeit. Die gesetzlichen Vorschriften der Europäischen Datenschutzgrundversordnung (DSGVO) und des
        Bundesdatenschutzgesetzes (BDSG) werden vom Anbieter befolgt. Darüber hinaus verarbeitet und nutzt der Anbieter
        personenbezogene Daten nur, soweit der betreffende Nutzer hierin einwilligt oder wenn dies sonst gesetzlich zulässig
        bzw. notwendig ist.&nbsp;</p>
      <p>(2) Für die Übertragung der Daten verwendet der Anbieter moderne Verschlüsselungstechniken (HTTPS). Es ist nicht
        gestattet, die Sicherungstechnik der App oder Elemente der Sicherungstechnik zu verletzen, zu umgehen, zurück zu
        entwickeln oder in sonstiger Weise unerlaubte Änderungen daran vorzunehmen, oder dieses zu versuchen, oder anderen
        dabei zu helfen.&nbsp;</p>
      <p>(3) Neben den bei der Registrierung angegebenen Daten werden bei der Nutzung der App keine weiteren
        personenbezogenen Daten erhoben oder vom Anbieter gespeichert. Die jeweiligen Inhaltsdaten werden allein vom Nutzer
        auf dessen Systemen hinterlegt. Der Anbieter hat hierauf keinen Zugriff. Die bei Registrierung angegebenen
        Nutzerdaten werden ausschließlich auf Servern innerhalb der Bundesrepublik Deutschland gespeichert und nicht an
        Dritte weitergegeben.&nbsp;</p>
      <p>(4) Weitere Informationen zum Datenschutz entnehmen Sie bitte der Datenschutzerklärung.&nbsp;</p>
      <h2>8. Eigentumsrechte an der App&nbsp;</h2>
      <p>(1) Alle Inhalte, die in der App und über die angeschlossenen Services verfügbar und zugänglich sind,
        einschließlich aller Designs, Texte, Grafiken, Videos, Anwendungen, Software, Datenbank und Dateien sowie die
        Funktionsweise und Anordnung der Seiteninhalte sind urheberrechtlich geschützt. Inhalte der App und Services dürfen
        ohne ausdrückliche Genehmigung des Anbieters weder ganz noch teilweise außerhalb der App genutzt, kopiert,
        vervielfältigt, verteilt, verändert, geframt, reproduziert, angezeigt, übertragen, verkauft, gepostet oder in
        sonstiger Weise Dritten zugänglich gemacht werden. Auch durch das Angebot zum Herunterladen von Daten werden keine
        weiteren Rechte übertragen. Hiervon ausgenommen sind lediglich die Daten, Bilder und sonstigen Inhalte, die der
        Nutzer aus eigenen Systemen verwendet und die nicht Gegenstand der App als solcher sind.&nbsp;</p>
      <p>(2) Jedem Nutzer der App wird vom Anbieter ein eingeschränktes, nicht übertragbares Nutzungsrecht gewährt, um die
        App mit seinen Diensten und Services in der rechtlich zulässigen Weise gemäß den Nutzungsbedingungen zu nutzen, die
        Inhalte zu verwenden und herunter zu laden.</p>
      <p>(3) Den Nutzern ist es untersagt, die App oder deren Inhalte ganz oder teilweise zu vervielfältigen, zu verbreiten,
        zu veröffentlichen, insbesondere im Internet öffentlich zugänglich zu machen, zu bearbeiten, umzugestalten und/oder
        zu verändern, den Quellcode oder die Struktur ihrer Inhalte zu ermitteln, und/oder unter Verwendung ihrer Inhalte
        abgeleitete Werke herzustellen. Den Nutzern ist ebenso wenig ein Recht eingeräumt, die in der App gegebenenfalls
        enthaltenen Marken oder Kennzeichen von Dritten zu benutzen.&nbsp;</p>
      <p>(4) Jegliche unerlaubte Nutzung ist untersagt und führt zum Erlöschen dieser Nutzungslizenz. Bei Verstößen gegen
        die Nutzungsbedingungen kann die Lizenz zur Nutzung der App vom Anbieter auch ohne Angabe von Gründen entzogen
        werden.</p>
      <h2>9. Gewährleistung&nbsp;</h2>
      <p>(1) Der Anbieter gewährleistet im Rahmen der vorhersehbaren Anforderungen eine dem jeweils üblichen technischen
        Standard entsprechende bestmögliche Wiedergabe der Inhalte der App. Dem Nutzer ist jedoch bekannt, dass die App
        lediglich eine Hilfestellung bieten kann und dass die auf der App ersichtlichen Ergebnisse in erheblichem Maß von
        der Belichtung, der Entfernung und der Qualität der angefertigten Lichtbilder sowie der Schichtstärke der Keramik
        abhängen, welche der Nutzer im Rahmen der Auswertung heranzieht.&nbsp;</p>
      <p>(2) Den Nutzern ist bekannt, dass die Daten und Dienste der App nicht jederzeit verfügbar sein können. Insbesondere
        für die ständige Verfügbarkeit des Downloads steht der Anbieter nicht ein.&nbsp;</p>
      <p>(3) Sollten Mängel an der zur Verfügung gestellten App auftreten, werden diese Mängel vom Anbieter nach
        entsprechender Mitteilung des Mangels durch den Nutzer innerhalb angemessener Zeit behoben. Die Mängelbehebung
        erfolgt nach Wahl des Anbieters entweder durch kostenfreie Nachbesserung oder Zurverfügungstellen neuer
        Anwendungen.&nbsp;</p>
      <p>(4) Der Nutzer darf eine Minderung nicht durch Abzug von dem vereinbarten Nutzungsentgelt durchsetzen.
        Entsprechende Bereicherungs- oder Schadensersatzansprüche bleiben unberührt.</p>
      <p>(5) Das Kündigungsrecht des Nutzers wegen Nichtgewährung des Gebrauchs nach § 543 Absatz 2 Satz 1 Nr. 1 BGB ist
        ausgeschlossen, sofern nicht die Nachbesserung oder Ersatzlieferung als fehlgeschlagen anzusehen ist.</p>
      <h2>10. Haftung</h2>
      <p>(1) Der Anbieter ist bemüht, das Angebot und die damit verbundenen Services stets aktuell und inhaltlich richtig
        sowie vollständig darzustellen. Dennoch ist das Auftreten von Fehlern nicht auszuschließen. Die Ergebnisse der App
        hängen insbesondere ganz erheblich von den vom Nutzer eingespeisten Inhalten und von der Art und Weise der Anwendung
        durch den Nutzer ab. Der Anbieter übernimmt daher mit Bereitstellung der App keinerlei Garantien irgendwelcher Art,
        insbesondere nicht hinsichtlich der Richtigkeit, Genauigkeit oder Zuverlässigkeit der bereitgestellten Informationen
        oder Inhalte.&nbsp;</p>
      <p>(2) Der Anbieter übernimmt darüber hinaus keine Haftung für die Richtigkeit der farblichen Darstellungen sowie für
        die Vollständigkeit und Richtigkeit der eingestellten Informationen. Die Ergebnisse der Farbanalyse in der App
        begründen keinen Anspruch auf Richtigkeit oder Fehlerfreiheit. Die Entscheidung über die Wahl der Keramikschichten
        obliegt allein dem Nutzer.&nbsp;</p>
      <p>(3) Der Anbieter haftet ferner nicht für eine auf den Systemen des Nutzers basierende Beeinträchtigung der
        Darstellung, des Programmablaufs oder sonstiger Unzulänglichkeiten, die auf Umständen in der Systemumgebung des
        Nutzers beruhen.&nbsp;</p>
      <p>(4) Der Anbieter haftet uneingeschränkt nach den gesetzlichen Bestimmungen lediglich für solche Schäden, welche auf
        vorsätzlichen oder grob fahrlässigen Pflichtverletzungen beruhen oder zur Schädigung des Lebens, des Körpers oder
        der Gesundheit eines Nutzers führen. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt. Die Haftung für
        einfache Fahrlässigkeit wird insoweit eingeschränkt, als die hierdurch entstehenden Schäden auf der Verletzung von
        Rechten, die den Nutzern nach Inhalt und Zweck des Vertrages gerade zu gewähren sind und/oder auf der Verletzung von
        Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und auf deren
        Einhaltung die Vertragspartner regelmäßig vertrauen und vertrauen dürfen (Kardinalpflichten), beruhen. Eine
        derartige Haftung ist darüber hinaus der Summe nach auf den vorhersehbaren und typischerweise bei solchen
        Vertragswerken entstehenden Schadensumfang begrenzt.&nbsp;</p>
      <p>(5) Eine weitergehende Haftung ist ohne Rücksicht auf die Rechtsnatur des geltend gemachten Anspruchs
        ausgeschlossen. Insbesondere ist eine verschuldensunabhängige Haftung des Anbieters für bereits bei
        Vertragsabschluss vorhandene Fehler nach § 536 a Absatz 1 BGB ausgeschlossen.&nbsp;</p>
      <p>(6) Der Anbieter haftet insbesondere nicht für Schäden, die dem Nutzer durch unsachgemäße Benutzung, dem Missbrauch
        der App oder dem Verlust von Daten aus der App entstehen.</p>
      <h2>11. Schlussbestimmung</h2>
      <p>(1) Für alle Streitigkeiten, die sich im Zusammenhang mit der Nutzung der App ergeben, gilt das Recht der
        Bundesrepublik Deutschland, sofern nicht der einem Nutzer, der seinen gewöhnlichen Aufenthalt in einem anderen Staat
        hat, durch zwingende Bestimmungen des Rechts dieses Staates gewährte Schutz hierdurch entzogen oder beeinträchtigt
        wird.&nbsp;</p>
      <p>(2) Bei Verträgen mit Kaufleuten, also Kunden, die ein Handelsgewerbe betreiben, oder aus anderen Rechtsgründen im
        HGB als Kaufmann eingeordnet werden sowie juristischen Personen des öffentlichen Rechts ist Augsburg ausschließlich
        Gerichtsstand für alle sich aus dem Nutzungsverhältnis unmittelbar oder mittelbar ergebenden Streitigkeiten.</p>
      <p>(3) Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam sein oder werden, so lässt dies die Wirksamkeit der
        übrigen Bestimmungen unberührt. Entsprechendes gilt für eventuelle Regelungslücken.</p>
      <p>(4) Der Anbieter ist berechtigt, die Erbringung aller Services jederzeit ganz oder teilweise auf Dritte zu
        übertragen.</p>
    </Legal>
  )
}

export default AGB
