import React, { useContext, useEffect, useMemo, useState } from 'react'
import JSZip from 'jszip'
import { FirebaseContext } from '@app/components/Firebase'
import UserContext from '@app/components/Session/context'
import DataTable from 'src/components/DataTable'
import Spinner from '@app/components/Spinner'
import Button from '@app/components/Button'
import Select from '@app/components/Select'
import styles from './InvoicesList.module.scss'

const monthNames = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
]

const InvoicesList = () => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(UserContext)

  const [invoices, setInvoices] = useState<FirestoreInvoiceWithId[]>()
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true)

  const [filterYear, setFilterYear] = useState(new Date().getFullYear())
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth() + 1)

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear()
    const options = []
    for (let year = currentYear; year >= 2023; year--) {
      options.push({
        id: year.toString(),
        label: year.toString(),
      })
    }
    return options
  }, [])

  // Load invoices
  useEffect(() => {
    if (firebase && user?.isAdmin) {
      const fromIso = `${filterYear}-${filterMonth.toString().padStart(2, '0')}-01`
      const toIso = `${filterYear}-${(filterMonth + 1).toString().padStart(2, '0')}-01`

      firebase.queryCollection(
        'invoices',
        [
          { property: 'created', operator: '>=', value: new Date(fromIso) },
          { property: 'created', operator: '<', value: new Date(toIso) }
        ]
      ).then(newInvoices => {
        setIsLoadingInvoices(false)
        setInvoices(newInvoices)
      })
    }
  }, [firebase, user, filterYear, filterMonth])

  const downloadInvoice = async (rowIndex: number) => {
    if (firebase && invoices) {
      const invoice = invoices[rowIndex]
      try {
        const url = await firebase.getUrlForStorageObject(`invoices/${invoice.id}.pdf`)

        const link = document.createElement('a')
        link.href = url
        link.download = `${invoice.invoiceNumber}.pdf`

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (e) {
        console.error(e);
      }
    }
  }

  const [downloadingInvoices, setDownloadingInvoices] = useState(false)
  const downloadAllInvoices = async () => {
    if (firebase && invoices) {
      setDownloadingInvoices(true)
      const zip = new JSZip()
      const promises = invoices.map(async invoice => {
        const url = await firebase.getUrlForStorageObject(`invoices/${invoice.id}.pdf`)
        const response = await fetch(url)
        const blob = await response.blob()
        zip.file(`${invoice.invoiceNumber}.pdf`, blob)
      })
      await Promise.all(promises)
      const blob = await zip.generateAsync({ type: 'blob' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `Rechnungen-${filterYear}-${monthNames[filterMonth - 1]}.zip`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setDownloadingInvoices(false)
    }
  }

  if (isLoadingInvoices)
    return <Spinner />

  return (
    <div>
      <div className={styles.toolBar}>
        <div className={styles.filters}>
          <Select
            label='Jahr'
            options={yearOptions}
            value={filterYear.toString()}
            onChange={newValue => setFilterYear(parseInt(newValue))}
          />
          <Select
            label='Monat'
            options={monthNames.map((month, index) => ({
              id: (index + 1).toString(),
              label: month
            }))}
            value={filterMonth.toString()}
            onChange={newValue => setFilterMonth(parseInt(newValue))}
          />
        </div>
        {downloadingInvoices ? <Spinner /> : <Button
          onClick={downloadAllInvoices}
          disabled={!invoices?.length}
        >
          Alle herunterladen
        </Button>}
      </div>
      <DataTable
        columnNames={[
          'Rechnungsnummer',
          'Datum',
        ]}
        rows={(invoices || [])?.map(invoice => ({
          key: invoice.invoiceNumber,
          values: [
            invoice.invoiceNumber,
            invoice.created.toDate().toLocaleDateString('de-DE')
          ]
        }))}
        rowActions={[
          rowIndex => <Button variant='link' onClick={() => downloadInvoice(rowIndex)}>Download</Button>
        ]}
      />
    </div>
  )
}

export default InvoicesList
