import React, { useContext, useEffect, useState } from 'react'
import Spinner from '@app/components/Spinner'
import DataTable from 'src/components/DataTable'
import Button from '@app/components/Button'
import { FirebaseContext } from '@app/components/Firebase'
import AddVoucherModal from './AddVoucherModal'
import UserContext from '@app/components/Session/context'
import styles from './VouchersList.module.scss'

const VouchersList = () => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(UserContext)

  const [vouchers, setVouchers] = useState<Voucher[]>([])
  const [isLoadingVouchers, setIsLoadingVouchers] = useState(true)
  const [error, setError] = useState('')
  const [showNewVoucherModal, setShowNewVoucherModal] = useState(false)

  // Subscribe to vouchers
  useEffect(() => {
    if (firebase && user?.isAdmin) {
      return firebase.subscribeToCollection('vouchers', newVouchers => {
        setIsLoadingVouchers(false)
        setVouchers(newVouchers)
      }, {
        property: 'created',
        type: 'date'
      })
    }
  }, [firebase, user])

  const handleNewVoucher = async (newVoucher: Voucher) => {
    setShowNewVoucherModal(false)
    setError('')
    if (firebase) {
      try {
        await firebase.addNewVoucher(newVoucher)
      } catch (e) {
        console.error(e);
        return setError('Neuer Gutschein konnte nicht gespeichert werden')
      }
    }
  }

  const handleInvalidateVoucher = async (rowIndex: number) => {
    setError('')
    if (window.confirm('Gutschein entwerten?')) {
      const newVouchers = [...vouchers]
      const selectedVoucher = newVouchers[rowIndex]
      if (firebase) {
        try {
          await firebase.invalidateVoucher(selectedVoucher.code)
        } catch (e) {
          console.error(e);
          return setError('Gutschein konnte nicht entwertet werden')
        }
      }
    }
  }

  if (isLoadingVouchers)
    return <Spinner />

  return (
    <div className={styles.vouchersList}>
      {error && <p style={{color: 'red', textAlign: 'center'}}>{error}</p>}

      <button
        className={styles.addVoucherButton}
        onClick={() => setShowNewVoucherModal(true)}
      ></button>

      <DataTable
        columnNames={[
          'Gutscheincode',
          'Entwertet',
          'Gültigkeitsdauer',
          'Einmalige Verwendung',
        ]}
        rows={vouchers.map(voucher => ({
          key: voucher.code,
          values: [
            voucher.code,
            voucher.invalidated,
            voucher.monthsValid + ' Monate',
            voucher.singleUse,
          ]
        }))}
        rowActions={[
          rowIndex => <Button variant='link' onClick={() => handleInvalidateVoucher(rowIndex)}>Entwerten</Button>
        ]}
      />

      {showNewVoucherModal && <AddVoucherModal
        onRequestClose={() => setShowNewVoucherModal(false)}
        onConfirm={handleNewVoucher}
        allVoucherCodes={vouchers.map(v => v.code)}
      />}
    </div>
  )
}

export default VouchersList
