import React, { Fragment } from 'react'
import styles from './DataTable.module.scss'

type CellValue = string | boolean | null

interface Props {
  columnNames: string[];
  rows: {
    key: string;
    values: CellValue[]
  }[];
  onRowClick?: (index: number) => void;
  rowActions?: ((index: number) => JSX.Element)[];
}

function DataTable({
  columnNames,
  rows,
  onRowClick,
  rowActions,
}: Props) {
  const renderCell = (cellValue: CellValue) => {
    switch (typeof cellValue) {
      case 'boolean':
        return cellValue ? 'Ja' : 'Nein'
    }
    return cellValue
  }

  return (
    <div className={[styles.dataTable, onRowClick && styles.clickable].join(' ')}>
      <table>
        <tbody>
          <tr>
            {columnNames.map(name => <th key={name}>{name}</th>)}
            {rowActions && <th/>}
          </tr>
          {rows.map((row, index) => (
            <tr key={row.key} onClick={onRowClick && (() => onRowClick(index))}>
              {row.values.map((cellValue, index) => <td key={index}>{renderCell(cellValue)}</td> )}
              {rowActions && <td><div className={styles.rowAction}>{rowActions.map((ra, raIndex) => <Fragment key={raIndex}>{ra(index)}</Fragment>)}</div></td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default DataTable
