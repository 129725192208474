import React, { useState,useContext } from 'react'
import { FirebaseContext, translateErrorMessage } from '../../Firebase'
import Card from '../../Card'
import TextField from '../../TextField'
import Button from '../../Button'
import logo from '../../../assets/dragonshade-logo.svg'
import styles from './PasswordForget.module.scss'
import { Link } from 'react-router-dom'
import { BASE_ROUTES } from '../../../constants/routes'
import { T, t } from '../../../i18n/i18n'

const PasswordForget = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [confirmed, setConfirmed] = useState(false)

  const firebase = useContext(FirebaseContext)

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    firebase && firebase
      .doPasswordReset(email)
      .then(() => {
        setEmail('')
        setConfirmed(true)
      })
      .catch(error => setError(translateErrorMessage(error)))
  }

  return (
    <Card className={styles.card}>
      <img src={logo} alt='Dragonshade Logo' className={styles.logo} />
      {!confirmed ? (<>
        <form onSubmit={onSubmit} className={styles.form}>
          <TextField autocomplete='username' label={t('auth.email')} value={email} onChange={setEmail}/>
          <Button type='submit'><T path='auth.resetPassword' /></Button>
          {error && <p className={styles.error}>{error}</p>}
        </form>
      </>) : (<>
        <p style={{textAlign: 'center'}}><T path='auth.passwordResetConfirm' /></p>
        <Link to={BASE_ROUTES.SIGN_IN}><Button><T path='auth.loginLink' /></Button></Link>
      </>)}
    </Card>
  )
}

export default PasswordForget
