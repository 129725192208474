import React from 'react'
import logo from '../../assets/dragonshade-logo.svg'

import styles from './SplashScreen.module.scss'

const SplashScreen = () => {
  return (
    <div className={styles.wrapper} >
      <div className={styles.logoWrapper} >
        <img src={logo} alt='Dragonshade Logo' className={styles.logo} />
      </div>
    </div>
  )
}

export default SplashScreen
