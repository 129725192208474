import React, { PropsWithChildren } from 'react'

import styles from './Checkbox.module.scss'

interface Props {
  value: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
}

const Checkbox = ({
  value,
  onChange,
  children,
  className
}: PropsWithChildren<Props>) => {
  return (
    <label className={[styles.checkbox, className].join(' ')}>
      <input
        type='checkbox'
        checked={value}
        onChange={e => onChange(e.target.checked)}
      />
      <div className={styles.checkBox} />
      <div className={styles.label}>{children}</div>
    </label>
  )
}

export default Checkbox
