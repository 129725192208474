/**
 * Dragonshade greycard luminance (lab) on a scale of 0 - 100
 */
export const GREYCARD_LUMINANCE = 44.69
/**
 * Greycard luminance correction factor
 */
export const GREYCARD_LUMINANCE_CORRECTION = .85

export const IS_APP = process.versions.hasOwnProperty('electron')
export const IS_DEV = IS_APP ?
  !(require('electron').remote)?.app?.isPackaged
:
  window.location.host.startsWith('localhost')

/**
 * Requiring package.json will work in app but fail on website,
 * thus USING_APP_VERSION is empty
 */
export let USING_APP_VERSION = ''
try {
  USING_APP_VERSION = require('../../package.json').version
} catch {}

export const SUBSCRIPTION_STATUS_LABELS = {
  subscribed: 'Abonniert',
  subscriptionPending: 'Abonnement wird verarbeitet',
  unsubscribed: 'Nicht abonniert',
}

export const PAYMENT_STATUS_LABELS = {
  pending: 'Ausstehend',
  failed: 'Fehlgeschlagen',
  paid: 'Bezahlt',
}

export const PAYMENT_METHOD_LABELS = {
  stripe: 'Kreditkarte',
  paypal: 'PayPal',
  voucher: 'Gutschein',
}

/**
 * y coordinate is measured from the gums on a scale of 0 - 1
 * dentin has to be unique for comparison
 */
export const defaultMeasurePoints = [
  {
    dentin: '0.3',
    y: .06
  }, {
    dentin: '0.5',
    y: .225
  }, {
    dentin: '0.7',
    y: .525
  }, {
    dentin: '0.8',
    y: .825
  }
]

export const LayerColors = [
  '#FFBA75',
  '#FFDBBC',
  '#A2E5FF',
]
