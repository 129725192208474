// Error codes: https://firebase.google.com/docs/reference/js/firebase.auth.Auth

const invalidLoginData = 'Ungültige E-Mail Adresse oder Passwort'

const ERRORS_DE: { [key: string]: string } = {
  'auth/email-already-in-use': 'Diese E-Mail Adresse wird bereits verwendet',
  'auth/invalid-email': 'Ungültige E-Mail Adresse',
  'auth/weak-password': 'Das Passwort sollte mindestens 6 Zeichen enthalten',
  'auth/account-exists-with-different-credential': invalidLoginData,
  'auth/invalid-credential': invalidLoginData,
  'auth/operation-not-allowed': invalidLoginData,
  'auth/user-disabled': invalidLoginData,
  'auth/user-not-found': invalidLoginData,
  'auth/wrong-password': invalidLoginData,
}

export default ERRORS_DE
