import React, { useEffect, useState } from 'react'

import styles from './Select.module.scss'

interface Props {
  label?: string;
  options: {
    label: string;
    id: string;
    disabled?: boolean;
  }[];
  value?: string;
  onChange: (value: string) => void;
  canBeEmpty?: boolean;
  className?: string;
  compact?: boolean;
  autocomplete?: string;
}

const Select = ({
  label,
  options,
  value,
  onChange,
  canBeEmpty = false,
  className,
  compact,
  autocomplete,
}: Props) => {
  const [hasContent, setHasContent] = useState(false)

  useEffect(() => {
    setHasContent(value !== undefined && value !== null && value !== '')
  }, [value])

  return (
    <div className={[styles.wrapper, hasContent ? styles.hasContent : null, className].join(' ')}>
      <select
        name={label}
        className={[styles.select, compact && styles.compact].join(' ')}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoComplete={autocomplete}
      >
        {canBeEmpty && <option disabled={!!value} value={undefined}></option>}
        {options.map(option => (
          <option value={option.id} key={option.id} disabled={option.disabled}>{option.label}</option>
        ))}
      </select>
      <label>{label}</label>
    </div>
  )
}

export default Select
