import React, { useState, useContext, useEffect } from 'react'
import { FirebaseContext, translateErrorMessage } from '../../Firebase'
import { BASE_ROUTES } from '../../../constants/routes'
import { useHistory, Link } from 'react-router-dom'
import { UserContext } from '../../Session'
import Card from '../../Card'
import TextField from '../../TextField'
import Button from '../../Button'
import logo from '../../../assets/dragonshade-logo.svg'
import styles from './SignIn.module.scss'
import { T, t } from '../../../i18n/i18n'

interface Props {
  successRedirect?: () => string;
  signUpRoute?: string;
}

const SignIn = ({
  successRedirect,
  signUpRoute
}: Props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const firebase = useContext(FirebaseContext)
  const user = useContext(UserContext)
  const history = useHistory()

  // Redirect user if already logged in
  useEffect(() => {
    if(user)
      history.push((successRedirect && successRedirect()) || BASE_ROUTES.HOME)
  }, [user, history, successRedirect])

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    firebase && firebase.doSignIn(email, password)
      .catch(error => setError(translateErrorMessage(error)))
  }

  return (
    <Card className={styles.card}>
      <img src={logo} alt='Dragonshade Logo' className={styles.logo} />
      <form onSubmit={onSubmit} className={styles.form}>
        <TextField autocomplete='username' label={t('auth.email')} value={email} onChange={setEmail}/>
        <TextField type='password' autocomplete='current-password' label={t('auth.password')} value={password} onChange={setPassword}/>
        <Button type='submit'><T path='auth.login' /></Button>
        {error && <p className={styles.error}>{error}</p>}
      </form>
      <p className={styles.footerLinks} >
        {signUpRoute && <><Link to={signUpRoute}><T path='auth.signUpLink' /></Link><br/></>}
        <Link to={BASE_ROUTES.PASSWORD_FORGET}><T path='auth.forgotPasswordLink' /></Link>
      </p>
    </Card>
  )
}

export default SignIn
