import React from 'react'
import Card from '@app/components/Card'

const Legal: React.FC = props =>
  <Card style={{
    width: '100%',
    maxWidth: '800px',
  }} {...props} />

export default Legal
