export default {
  LANDING: '/',
  SIGN_UP: '/registrieren',
  SIGN_IN: '/login',
  PASSWORD_FORGET: '/passwort-vergessen',
  SUBSCRIBE: '/abonnieren',
  DOWNLOAD: '/download',
  ACCOUNT: '/account',
  IMPRESSUM: '/impressum',
  PRIVACY: '/datenschutz',
  AGB: '/agb',
  ADMIN: '/admin',
}
