import React, { useContext, useEffect, useState } from 'react'
import Card from '@app/components/Card'
import styles from './Admin.module.scss'
import Tabs from '@app/components/Tabs'
import UserContext from '@app/components/Session/context'
import ROUTES from '../../../constants/routes'
import { useHistory } from 'react-router-dom'
import UsersList from './users/UsersList'
import VouchersList from './vouchers/VouchersList'
import InvoicesList from './invoices/InvoicesList'

const Admin = () => {
  const user = useContext(UserContext)
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState('users')

  // Redirect user to home if no admin
  useEffect(() => {
    if (!user?.isAdmin)
      history.push(ROUTES.LANDING)
  }, [user, history])

  return (<>
    <Card className={styles.admin}>
      <div className={styles.toolbar}>
        <Tabs
          tabs={[
            {
              title: 'Abonnenten',
              value: 'users'
            }, {
              title: 'Gutscheine',
              value: 'vouchers'
            }, {
              title: 'Rechnungen',
              value: 'invoices'
            }
          ]}
          currentTab={currentTab}
          onChange={setCurrentTab}
          className={styles.tabs}
        />
      </div>

      {currentTab === 'users' && <UsersList />}
      {currentTab === 'vouchers' && <VouchersList />}
      {currentTab === 'invoices' && <InvoicesList />}
    </Card>
  </>)
}

export default Admin
