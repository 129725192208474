import React, { useContext, useMemo } from 'react'
import de from './de.json'
import en from './en.json'
import { UserContext } from '../components/Session'

const LANG_STRINGS = { de, en }
export const DEFAULT_LANG = 'de'
export const langKeys = Object.keys(LANG_STRINGS)
export const langLabels: Record<string, string> = {
  de: 'Deutsch',
  en: 'English',
}

// type PathsToStringProps<T> = T extends string ? [] : {
//   [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>]
// }[Extract<keyof T, string>];

// type Join<T extends string[], D extends string> =
//   T extends [] ? never :
//   T extends [infer F] ? F :
//   T extends [infer F, ...infer R] ?
//   F extends string ?
//   `${F}${D}${Join<Extract<R, string[]>, D>}` : never : string;

export type TranslationPaths = string // Join<PathsToStringProps<typeof de>, '.'>

interface TProps {
  path: TranslationPaths;
  params?: (string | undefined | null)[];
}

const DEBUG_TRANSLATIONS = false

export const t = (path: string, params?: any, lang?: string) => {
  const langResolved = lang || localStorage.getItem('lang') || DEFAULT_LANG
  const translationResult: string = path.split('.').reduce((a: any, b) => a?.[b], LANG_STRINGS[langResolved]) || ''

  if (!params)
    return translationResult

  let result = translationResult
  params.forEach(param => {
    const replacement = (typeof param === 'undefined' || param === null) ? '' : param.toString()
    result = result.replace('%s', replacement)
  })

  // Replace remaining tags
  result = result.replaceAll('%s', '')

  return result
}

export const useT = (path: string, params?: any) => {
  const user = useContext(UserContext)

  const mergedResult = useMemo(() => {
    return t(path, params, user?.lang)
  }, [user, params, path])

  return mergedResult
}

export const T = ({ path, params }: TProps) => {
  const translationResult = useT(path, params)
  const elementProps = {
    style: DEBUG_TRANSLATIONS ? {color: 'green'} : undefined
  }

  if (path.endsWith(':HTML')) {
    return <div {...elementProps} dangerouslySetInnerHTML={{__html: translationResult}} />
  }

  return <span {...elementProps}>{translationResult}</span>
}
