import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/database'
import 'firebase/storage'
import { IS_DEV, USING_APP_VERSION } from '../../constants/app'

const firebaseConfig = {
  apiKey: "AIzaSyDilgYgs_Eimci52_EKaf6Vp6yvHE0j6iw",
  authDomain: "dragonshade-app.firebaseapp.com",
  databaseURL: "https://dragonshade-app.firebaseio.com",
  projectId: "dragonshade-app",
  storageBucket: "dragonshade-app.appspot.com",
  messagingSenderId: "111490034784",
  appId: "1:111490034784:web:e5d77cba82c8b1a914e705",
  measurementId: "G-PETK55JKPP"
}

class Firebase {
  auth: app.auth.Auth
  db: app.firestore.Firestore
  database: app.database.Database
  functionsBaseUrl: string
  functions: app.functions.Functions
  storage: app.storage.Storage
  getReleaseNotes: () => Promise<ReleaseNote[]>
  getLibrary: () => Promise<PreDefinedLibrary[]>
  cancelSubscription: () => Promise<{
    success: boolean;
    periodEnd?: string;
  }>
  getAllUsers: (data?: {nextPageToken?: string}) => Promise<{
    nextPageToken: string | undefined;
    users: AppUser[];
  } | undefined>
  redeemVoucher: (data: {voucherCode: string}) => Promise<{
    valid: boolean;
    voucher?: Voucher;
  } | undefined>
  createMollieSubscription: () => Promise<{
    success: boolean;
    redirectUrl?: string;
  }>
  validateUstId: (data: {ustId: string}) => Promise<{
    result: 'valid' | 'invalid' | 'temporarilyUnavailable';
  }>
  getMonthlySubscriptionCost: () => Promise<{
    net: number;
    gross: number;
  }>
  getUserPaysVat: () => Promise<{ paysVat: boolean }>
  getCountryCodesWithEuVat: () => Promise<string[]>

  constructor() {
    app.initializeApp(firebaseConfig)

    this.auth = app.auth()
    this.db = app.firestore()
    this.database = app.database()
    this.functions = app.functions()
    this.storage = app.storage()

    if(IS_DEV) {
      this.functions.useFunctionsEmulator('http://localhost:5002')
      this.functionsBaseUrl = 'http://localhost:5002/dragonshade-app/us-central1/'
    } else {
      this.functionsBaseUrl = 'https://us-central1-dragonshade-app.cloudfunctions.net/'
    }

    this.getReleaseNotes = this.createHttpsCallable('getReleaseNotes')
    this.getLibrary = this.createHttpsCallable('getLibrary')
    this.cancelSubscription = this.createHttpsCallable('cancelSubscription')
    this.getAllUsers = this.createHttpsCallable('getAllUsers')
    this.redeemVoucher = this.createHttpsCallable('redeemVoucher')
    this.createMollieSubscription = this.createHttpsCallable('createMollieSubscription')
    this.validateUstId = this.createHttpsCallable('validateUstId')
    this.getMonthlySubscriptionCost = this.createHttpsCallable('getMonthlySubscriptionCost')
    this.getUserPaysVat = this.createHttpsCallable('getUserPaysVat')
    this.getCountryCodesWithEuVat = this.createHttpsCallable('getCountryCodesWithEuVat')
  }

  createHttpsCallable = (
    name: string,
  ): ((data?: any) => Promise<any>) => {
    const callable = this.functions.httpsCallable(name);
    return async (data?: any) => (await callable({appVersion: USING_APP_VERSION, ...data})).data;
  };

  dbUserDefaultValues: DatabaseUser = {
    isAdmin: false,
    name: '',
    subscriptionStatus: 'unsubscribed',
    paymentMethod: null,
    newsletterConsented: false,
    lang: navigator.language.slice(0,2) === 'de' ? 'de' : 'en',
  }

  doCreateUser = ({
    email,
    password,
    ...userData
  }: {
    email: string,
    password: string,
    name: string,
    company: string,
    address: any,
    ustId: string | undefined,
    newsletterConsented: boolean
  }): Promise<app.auth.UserCredential> => {
    return new Promise(async (resolve, reject) => {
      try {
        const createdUser = await this.auth.createUserWithEmailAndPassword(email, password)
        await createdUser.user?.sendEmailVerification()

        const unTypedUserData: any = userData
        const dbUserData = Object.keys(userData).reduce<any>((acc, key: any) => {
          if (typeof unTypedUserData[key] !== 'undefined')
            acc[key] = unTypedUserData[key]

          return acc
        }, {})

        if (createdUser.user?.uid) {
          try {
            await this.getUser(createdUser.user.uid)
              .set({
                ...this.dbUserDefaultValues,
                ...dbUserData,
              }, { merge: true })

            resolve(createdUser)

          } catch (error) {
            console.error(error)
            reject({message: 'Fehler beim Schreiben der Benutzer Account Informationen'})
          }
        } else {
          reject({message: 'Fehler beim Erstellen des Benutzer Accounts'})
        }

      } catch (error) {
        reject(error)
      }
    })
  }

  doSendVerificationEmail = () => {
    return this.auth.currentUser?.sendEmailVerification()
  }

  doSignIn = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () =>
    this.auth.signOut()

  doPasswordReset = (email: string) =>
    this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = (password: string) =>
   this.auth.currentUser && this.auth.currentUser.updatePassword(password)

  onAuthStateChanged = (callback: (authUser: AppUser | null) => void) =>
    this.auth.onAuthStateChanged(authUser => {
      if (!authUser)
        return callback(null)

      this.getUser(authUser.uid)
        .get()
        .then(snapshot => {
          let dbUser = snapshot.data()

          const appUser: AppUser = {
            ...authUser,
            ...{
              ...this.dbUserDefaultValues,
              ...dbUser
            }
          }

          callback(appUser)
        })
    })

  subscribeToUserData = (
    callback: (userData: DatabaseUser) => void
  ) => {
    if (!this.auth.currentUser?.uid)
      return undefined
    return this.subscribeToDocumentData('users', this.auth.currentUser.uid, data => {
      callback(data)
    })
  }

  getUser = (uid: string) => this.db.collection('users').doc(uid)

  updateUserData = (userData: Partial<DatabaseUser>) => {
    if (this.auth.currentUser?.uid)
      return this
        .getUser(this.auth.currentUser.uid)
        .set(userData, { merge: true })
  }

  queryCollection = (
    collection: string,
    where: {
      property: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[],
  ): Promise<any[]> => {
    return new Promise(resolve => {
      const ref = this.db.collection(collection)

      let query: app.firestore.Query = ref
      where.forEach(({ property, operator, value }) => {
        query = query.where(property, operator, value)
      })

      query.onSnapshot(snapshot => {
        const result = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        resolve(result)
      })
    })
  }

  subscribeToDocumentData = (
    collection: string,
    document: string,
    callback: (data: any) => void
  ) =>
    this.db.collection(collection).doc(document).onSnapshot(doc => {
      const data = doc.data()
      if (data)
        callback(data)
    })

  subscribeToCollection = (
    collection: string,
    callback: (data: any[]) => void,
    sortBy?: {
      property: string;
      type: 'date';
    },
  ) =>
    this.db.collection(collection).onSnapshot(snapshot => {
      let result = snapshot.docs.map(doc => doc.data())

      if (sortBy) {
        result = result.sort((a, b) => {
          let aProp = a[sortBy.property]
          let bProp = b[sortBy.property]
          switch (sortBy.type) {
            case 'date':
              return bProp.seconds - aProp.seconds
            default:
              return bProp - aProp
          }
        })
      }

      callback(result)
    })

  getUrlForStorageObject = (path: string) => {
    return this.storage.ref(path).getDownloadURL()
  }

  setLang = (lang: string) => {
    // Add lang to localStorage for modals outside of user context
    localStorage.setItem('lang', lang)
    if (this.auth.currentUser) {
      this.getUser(this.auth.currentUser.uid)
        .set({ lang }, { merge: true })
    }
  }

  setPayPalSubscriptionID = (subscriptionID: string) => {
    if (this.auth.currentUser) {
      this.getUser(this.auth.currentUser.uid)
        .set({
          payPalSubscriptionID: subscriptionID,
          paymentMethod: 'paypal'
        }, { merge: true })
    }
  }

  getLibraryImageUrl = ({
    library,
    column,
    rowIndex
  }: {
    library: string,
    column: string,
    rowIndex: number
  }) => {
    const libraryParam = encodeURIComponent(library)
    const columnParam = encodeURIComponent(column)
    const rowIndexParam = encodeURIComponent(rowIndex)
    return `${this.functionsBaseUrl}getLibraryImage?library=${libraryParam}&column=${columnParam}&rowIndex=${rowIndexParam}&appVersion=${USING_APP_VERSION}`
  }

  addNewVoucher = async (newVoucher: Voucher) => {
    return this.db.collection('vouchers').add({...newVoucher, created: new Date()})
  }

  invalidateVoucher = async (voucherCode: string) => {
    const querySnapshot = await this.db.collection('vouchers').where('code', '==', voucherCode).get()
    for (const doc of querySnapshot.docs) {
      await doc.ref.set({
        invalidated: true
      }, {merge: true})
    }
  }
}

export default Firebase
