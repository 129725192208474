import React, { useContext } from 'react'
import { FirebaseContext } from '../Firebase'
import { BASE_ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'
import Button from '../Button'
import { T } from '../../i18n/i18n'

interface Props {
  className?: string;
}

const SignOut = ({className}: Props) => {
  const firebase = useContext(FirebaseContext)
  const history = useHistory()

  const signOut = () => {
    firebase && firebase.doSignOut()
    history.push(BASE_ROUTES.HOME)
  }

  return (
    <div>
      <Button
        className={className}
        variant='link'
        style={{color: 'red'}}
        onClick={signOut}
      ><T path='account.logout' /></Button>
    </div>
  )
}

export default SignOut
