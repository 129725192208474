import React, { useContext, useEffect, useState } from 'react'
import { SUBSCRIPTION_STATUS_LABELS } from '@app/constants/app'
import { FirebaseContext } from '@app/components/Firebase'
import UserContext from '@app/components/Session/context'
import Spinner from '@app/components/Spinner'
import DataTable from 'src/components/DataTable'
import UserDetailsModal from './UserDetailsModal'
import Button from '@app/components/Button'
import styles from './UsersList.module.scss'

const UsersList = () => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(UserContext)

  const [users, setUsers] = useState<AppUser[]>([])
  const [isLoadingUsers, setIsLoadingUsers] = useState(true)
  const [modalUserData, setModalUserData] = useState<AppUser | undefined>(undefined)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  // Load app users
  useEffect(() => {
    if (firebase && user?.isAdmin) {
      // Implement pagination with nextPageToken here when there are more than 1000 users
      firebase.getAllUsers()
        .then(result => {
          if (result) {
            setUsers(result.users)
          }
          setIsLoadingUsers(false)
        })
    }
  }, [firebase, user])

  const downloadNewsletterSubscribers = async () => {
    setIsLoadingExport(true)
    const XLSX = await import('xlsx')
    const userDataMap: {[key: string]: keyof AppUser} = {
      'Name': 'name',
      'E-Mail': 'email',
      'Unternehmen': 'company',
    }

    const usersToExport = users
      .filter(user => user.emailVerified && user.newsletterConsented)
      .map(user => {
        const exportUser: {[key: string]: any} = {}
        Object.entries(userDataMap).forEach(([label, key]) => {
          exportUser[label] = user[key]
        })
        return exportUser
      })

    const userSheet = XLSX.utils.json_to_sheet(usersToExport);
    const userWorkbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(userWorkbook, userSheet, 'Dragonshade App Newsletter')
    XLSX.writeFile(userWorkbook, 'Dragonshade App Newsletter.xlsx');
    setIsLoadingExport(false)
  }

  if (isLoadingUsers)
    return <Spinner />

  return (
    <div className={styles.usersList}>
      {!isLoadingUsers && (
        isLoadingExport ? <Spinner size='small' /> : (
          <Button
            variant='link'
            onClick={downloadNewsletterSubscribers}
          >
            Newsletter Abonnenten exportieren
          </Button>
        )
      )}
      <DataTable
        columnNames={[
          'Name',
          'E-Mail',
          'Unternehmen',
          'Status',
        ]}
        rows={users.map(user => ({
          key: user.uid,
          values: [
            user.name,
            user.email,
            user.company || '',
            SUBSCRIPTION_STATUS_LABELS[user.subscriptionStatus],
          ]
        }))}
        onRowClick={index => setModalUserData(users[index])}
      />

      {modalUserData && <UserDetailsModal
        onRequestClose={() => setModalUserData(undefined)}
        userData={modalUserData}
      />}
    </div>
  )
}

export default UsersList
