import React, { useState, useContext, useMemo, useEffect } from 'react'
import { FirebaseContext, translateErrorMessage } from '@app/components/Firebase'
import ROUTES from '../../../constants/routes'
import { useHistory } from 'react-router-dom'
import Card from '@app/components/Card'
import TextField from '@app/components/TextField'
import Button from '@app/components/Button'
import Checkbox from '@app/components/Checkbox'
import logo from '@app/assets/dragonshade-logo.svg'
import styles from './SignUp.module.scss'
import { t, T } from '@app/i18n/i18n'
import Select from '@app/components/Select'
import { UserContext } from '@app/components/Session'
import { countriesDe, countriesEn } from '@app/constants/countries'
import Spinner from '@app/components/Spinner'

const SignUp = () => {
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const user = useContext(UserContext)

  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [address, setAddress] = useState<NonNullable<AppUser['address']>>({})
  const [ustId, setUstId] = useState('')

  const [privacyConsented, setPrivacyConsented] = useState(false)
  const [agbConsented, setAgbConsented] = useState(false)
  const [newsletterConsented, setNewsletterConsented] = useState(false)
  const [error, setError] = useState('')

  const countriesOptions = useMemo(() => {
    const userLang = user?.lang || localStorage.getItem('lang') || 'de'
    const countries = userLang === 'de' ? countriesDe : countriesEn

    return countries.map(country => ({
      label: country.label,
      id: country.iso
    }))
  }, [user])

  const [countryCodesWithEuVat, setCountryCodesWithEuVat] = useState<string[]>()
  useEffect(() => {
    firebase?.getCountryCodesWithEuVat().then(setCountryCodesWithEuVat)
  }, [firebase])

  const countryHasEuVat = useMemo(() => {
    if (!address.countryIso || !countryCodesWithEuVat)
      return false

    return countryCodesWithEuVat.includes(address.countryIso)
  }, [address.countryIso, countryCodesWithEuVat])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!firebase)
      return

    setIsSubmitting(true)
    setError('')

    if (!name) {
      setError(t('auth.errors.name'))
      setIsSubmitting(false)
      return
    }

    if (!privacyConsented) {
      setError(t('auth.errors.privacy'))
      setIsSubmitting(false)
      return
    }

    if (!agbConsented) {
      setError(t('auth.errors.agb'))
      setIsSubmitting(false)
      return
    }

    // Validate Umsatzsteuer-ID
    if (countryHasEuVat && ustId) {
      try {
        const { result: ustIdValid } = await firebase.validateUstId({ ustId })
        if (ustIdValid === 'invalid') {
          setError(t('auth.errors.ustId.invalid'))
          return
        }

        if (ustIdValid === 'temporarilyUnavailable') {
          setError(t('auth.errors.ustId.temporarilyUnavailable'))
          return
        }
      } catch (error) {
        setError(t('auth.errors.ustId.temporarilyUnavailable'))
        return
      } finally {
        setIsSubmitting(false)
      }
    }

    try {
      await firebase.doCreateUser({
        name,
        email,
        password,
        company,
        address,
        ustId: countryHasEuVat ? ustId : undefined,
        newsletterConsented,
      })
    } catch (error) {
      setError(translateErrorMessage(error as any))
      return
    } finally {
      setIsSubmitting(false)
    }

    history.push(ROUTES.LANDING)
  }

  return (
    <Card className={styles.card}>
      <img src={logo} alt='Dragonshade Logo' className={styles.logo} />

      <form onSubmit={onSubmit} className={styles.form}>
        <TextField label={t('auth.name') + ' *'} autocomplete='name' value={name} onChange={setName} />
        <TextField label={t('auth.email') + ' *'} autocomplete="username" value={email} onChange={setEmail}/>
        <TextField type='password' autocomplete="new-password" label={t('auth.password') + ' *'} value={password} onChange={setPassword}/>

        <div className={styles.headline}><T path='auth.billingAddress' /></div>
        <TextField label={t('auth.company')} autocomplete='organization' value={company} onChange={setCompany} />
        <TextField label={t('auth.street')} autocomplete='street-address' value={address.street || ''} onChange={value => setAddress({ ...address, street: value })} />
        <TextField label={t('auth.postalCode')} autocomplete='postal-code' value={address.postalCode || ''} onChange={value => setAddress({ ...address, postalCode: value })} />
        <TextField label={t('auth.city')} autocomplete='address-level2' value={address.city || ''} onChange={value => setAddress({ ...address, city: value })} />
        <Select
          label={t('auth.country')}
          autocomplete='country'
          options={countriesOptions}
          canBeEmpty
          value={address.countryIso || ''}
          onChange={value => setAddress({ ...address, countryIso: value })}
          className={styles.select}
        />
        {countryHasEuVat && (
          <TextField label={t('auth.ustId')} autocomplete='do-not-autofill' value={ustId} onChange={setUstId} />
        )}

        <Checkbox className={styles.consentCheckbox} value={privacyConsented} onChange={setPrivacyConsented}>
          <T path='auth.privacyConsent:HTML' />
        </Checkbox>
        <Checkbox className={styles.consentCheckbox} value={agbConsented} onChange={setAgbConsented}>
          <T path='auth.agbConsent:HTML' />
        </Checkbox>
        <Checkbox className={styles.consentCheckbox} value={newsletterConsented} onChange={setNewsletterConsented}>
          <T path='auth.newsletterConsent' />
        </Checkbox>

        <T path='auth.requiredFields' />

        {error && <p className={styles.error}>{error}</p>}

        {isSubmitting ? (
          <Spinner />
        ): (
          <Button type='submit'><T path='auth.register' /></Button>
        )}
      </form>
    </Card>
  )
}

export default SignUp
