import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './BillingAddressTable.module.scss'
import { countriesDe, countriesEn, getCountryLabel } from '../../../constants/countries'
import TextField from '../../TextField'
import Select from '../../Select'
import Button from '../../Button'
import { T, t } from '../../../i18n/i18n'
import { UserContext } from '../../Session'
import { FirebaseContext } from '../../Firebase'

type ProfileData = Pick<DatabaseUser, 'company' | 'address' | 'ustId'>

const BillingAddressTable = () => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(UserContext)
  const [isEditingProfile, setIsEditingProfile] = useState(false)
  const [newProfileData, setNewProfileData] = useState<ProfileData>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (isEditingProfile) {
      setError('')
      setIsSubmitting(false)
      setNewProfileData({
        company: user?.company || '',
        address: user?.address || {},
        ustId: user?.ustId || '',
      })
    }
  }, [isEditingProfile, user])

  const countriesOptions = useMemo(() => {
    const userLang = user?.lang || localStorage.getItem('lang') || 'de'
    const countries = userLang === 'de' ? countriesDe : countriesEn

    return countries.map(country => ({
      label: country.label,
      id: country.iso
    }))
  }, [user])

  const saveProfile = async () => {
    if (!firebase)
      return

    setError('')
    setIsSubmitting(true)

    // Validate Umsatzsteuer-ID
    if (newProfileData.ustId && newProfileData.ustId !== user?.ustId) {
      try {
        const { result: ustIdValid } = await firebase.validateUstId({
          ustId: newProfileData.ustId,
        })
        if (ustIdValid === 'invalid') {
          setError(t('auth.errors.ustId.invalid'))
          setIsSubmitting(false)
          return
        }

        if (ustIdValid === 'temporarilyUnavailable') {
          setError(t('auth.errors.ustId.temporarilyUnavailable'))
          setIsSubmitting(false)
          return
        }
      } catch (error) {
        setError(t('auth.errors.ustId.temporarilyUnavailable'))
        setIsSubmitting(false)
        return
      }
    }

    await firebase.updateUserData(newProfileData)

    setIsSubmitting(false)
    setIsEditingProfile(false)
  }

  return (
    <>
      {isEditingProfile ? (
        <div className={styles.editingForm}>
          <TextField
            label={t('auth.company')}
            autocomplete='organization'
            value={newProfileData.company || ''}
            onChange={newVal => {
              setNewProfileData({
                ...newProfileData,
                company: newVal,
              })
            }}
          />
          <TextField
            label={t('auth.street')}
            autocomplete='street-address'
            value={newProfileData.address?.street || ''}
            onChange={newVal => {
              setNewProfileData({
                ...newProfileData,
                address: {
                  ...newProfileData.address,
                  street: newVal,
                },
              })
            }}
          />
          <TextField
            label={t('auth.postalCode')}
            autocomplete='postal-code'
            value={newProfileData.address?.postalCode || ''}
            onChange={newVal => {
              setNewProfileData({
                ...newProfileData,
                address: {
                  ...newProfileData.address,
                  postalCode: newVal,
                },
              })
            }}
          />
          <TextField
            label={t('auth.city')}
            autocomplete='address-level2'
            value={newProfileData.address?.city || ''}
            onChange={newVal => {
              setNewProfileData({
                ...newProfileData,
                address: {
                  ...newProfileData.address,
                  city: newVal,
                },
              })
            }}
          />
          <Select
            label={t('auth.country')}
            autocomplete='country'
            options={countriesOptions}
            canBeEmpty
            value={newProfileData.address?.countryIso || ''}
            onChange={value => {
              setNewProfileData({
                ...newProfileData,
                address: {
                  ...newProfileData.address,
                  countryIso: value,
                },
              })
            }}
            className={styles.select}
          />
          <TextField
            label={t('auth.ustId')}
            autocomplete='do-not-autofill'
            value={newProfileData.ustId || ''}
            onChange={newVal => {
              setNewProfileData({
                ...newProfileData,
                ustId: newVal,
              })
            }}
          />

          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      ) : (
        <table className={styles.billingAddressTable}>
          <tbody>
            <tr>
              <td><T path='auth.company' /></td>
              <td>{user?.company}</td>
            </tr>
            <tr>
              <td><T path='auth.street' /></td>
              <td>{user?.address?.street}</td>
            </tr>
            <tr>
              <td><T path='auth.postalCode' /></td>
              <td>{user?.address?.postalCode}</td>
            </tr>
            <tr>
              <td><T path='auth.city' /></td>
              <td>{user?.address?.city}</td>
            </tr>
            <tr>
              <td><T path='auth.country' /></td>
              <td>{getCountryLabel(user?.lang || localStorage.getItem('lang') || 'de', user?.address?.countryIso)}</td>
            </tr>
            <tr>
              <td><T path='auth.ustId' /></td>
              <td>{user?.ustId}</td>
            </tr>
          </tbody>
        </table>
      )}
      {isEditingProfile ? (
        <>
          <Button onClick={() => saveProfile()} disabled={isSubmitting}>
            <T path='general.save' />
          </Button>
          <Button
            variant='link'
            style={{ marginLeft: '1rem' }}
            onClick={() => setIsEditingProfile(false)}
          >
            <T path='general.cancel' />
          </Button>
        </>
      ) : (
        <Button onClick={() => setIsEditingProfile(true)}>
          <T path='general.edit' />
        </Button>
      )}
    </>
  )
}

export default BillingAddressTable
