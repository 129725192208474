import React, { useContext, useState, useEffect } from 'react'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'

import ROUTES from '../../constants/routes'
import SplashScreen from '@app/components/SplashScreen'
import Navigation, { NavigationProps } from '@app/components/Navigation'
import LandingPage from '../Routes/Landing'
import SignInPage from '@app/components/Routes/SignIn'
import PasswordForgetPage from '@app/components/Routes/PasswordForget'
import SignUpPage from '../Routes/SignUp'
import SubscribePage from '../Routes/Subscribe'
import DownloadPage from '../Routes/Download'
import AccountPage from '@app/components/Routes/Account'
import ImpressumPage from '../Routes/Legal/Impressum'
import PrivacyPage from '../Routes/Legal/Privacy'
import AgbPage from '../Routes/Legal/AGB'
import AdminPage from '../Routes/Admin'
import { T, t } from '@app/i18n/i18n'

import { FirebaseContext } from '@app/components/Firebase'
import { UserContext } from '@app/components/Session'

import styles from './App.module.scss'

const App = () => {
  const firebase = useContext(FirebaseContext)
  const [user, setUser] = useState<AppUser | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {

    if(firebase) {
      return firebase.onAuthStateChanged(user => {
        setUser(user)
        setIsLoading(false)
      })
    }
  }, [firebase])

  // Update user object when user data has changed
  const userIsSet = !!user
  useEffect(() => {
    if (firebase && userIsSet) {
      return firebase.subscribeToUserData(userData => {
        setUser(prevUser => {
          if (!prevUser)
            return prevUser
          return {
            ...prevUser,
            ...userData
          }
        })
      })
    }
  }, [firebase, userIsSet])

  const navLinks: NavigationProps['links'] = [
    {
      to: 'https://dragon-dental.shop/',
      text: t('website.navigation.shop'),
      external: true
    }, {
      to: 'http://www.drachenberg-dental.de/',
      text: t('website.navigation.laborWebsite'),
      external: true
    }
  ]

  if (user?.isAdmin) {
    navLinks.push({
      to: ROUTES.ADMIN,
      text: 'Admin'
    })
  }

  return (
    <div className={styles.app}>
      <UserContext.Provider value={user}>
        {!isLoading ?
          <Router>
            <Navigation isWebsite homeLink={ROUTES.LANDING} links={navLinks}/>
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route path={ROUTES.SIGN_IN} component={() => <SignInPage
              successRedirect={() => user?.subscriptionStatus === 'subscribed' ? ROUTES.DOWNLOAD : ROUTES.SUBSCRIBE}
              signUpRoute={ROUTES.SIGN_UP}
            />} />
            <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SUBSCRIBE} component={SubscribePage} />
            <Route path={ROUTES.DOWNLOAD} component={DownloadPage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.IMPRESSUM} component={ImpressumPage} />
            <Route path={ROUTES.PRIVACY} component={PrivacyPage} />
            <Route path={ROUTES.AGB} component={AgbPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
            <div className={styles.footerNav}>
              <Link to={ROUTES.IMPRESSUM}><T path='website.navigation.impressum' /></Link>
              <Link to={ROUTES.PRIVACY}><T path='website.navigation.privacy' /></Link>
              <Link to={ROUTES.AGB}><T path='website.navigation.agb' /></Link>
            </div>
          </Router> : <SplashScreen />}
      </UserContext.Provider>
    </div>
  )
}

export default App
