import React from 'react'
import BaseModal from '@app/components/Modals/BaseModal'
import Button from '@app/components/Button'
import { PAYMENT_METHOD_LABELS, PAYMENT_STATUS_LABELS, SUBSCRIPTION_STATUS_LABELS } from '@app/constants/app'
import { getCountryLabel } from '@app/constants/countries'
import styles from './UserDetailsModal.module.scss'

interface Props {
  onRequestClose: () => void;
  userData: AppUser;
}

const UserDetailsModal: React.FC<Props> = ({
  onRequestClose,
  userData
}) => {
  const dataLabelMap = [
    {
      'E-Mail': <a href={'mailto:'+userData.email}>{userData.email}</a>,
      'Adresse': (
        <div>
          {userData.address?.street}<br/>
          {userData.address?.postalCode} {userData.address?.city}<br/>
          {getCountryLabel('de', userData.address?.countryIso)}
        </div>
      ),
      'Ust-IdNr.': userData.ustId,
      'Status': SUBSCRIPTION_STATUS_LABELS[userData.subscriptionStatus],
      'Newsletter': userData.newsletterConsented ? 'Ja' : 'Nein',
      'E-Mail verifiziert': userData.emailVerified ? 'Ja' : 'Nein',
      'Erstellt': userData.metadata.creationTime && new Date(userData.metadata.creationTime).toLocaleString(),
      'Zuletzt angemeldet': userData.metadata.lastSignInTime && new Date(userData.metadata.lastSignInTime).toLocaleString(),
      'Zuletzt aktiv': (userData.metadata as any).lastRefreshTime && new Date((userData.metadata as any).lastRefreshTime).toLocaleString(),
    }, {
      'Zahlungsmethode': userData.paymentMethod ? PAYMENT_METHOD_LABELS[userData.paymentMethod] : '',
      'PayPal Abonnement Id': userData.payPalSubscriptionID,
      'Stripe Abonnement Id': userData.stripeSubscriptionID,
      'Letzter Zahlungsstatus': userData.lastPaymentStatus && PAYMENT_STATUS_LABELS[userData.lastPaymentStatus],
      'Mollie Customer ID': userData.mollieCustomerID,
      'Mollie Subscription ID': userData.mollieSubscriptionID,
      'Kündigungsdatum': userData.subscriptionCancelDate && new Date(userData.subscriptionCancelDate.toDate()).toLocaleDateString(),
      'Gutschein gültig bis': userData.voucherValidUntil?._seconds && new Date(userData.voucherValidUntil?._seconds*1000).toLocaleDateString(),
      'Verwendete Gutscheine': userData.usedVouchers?.join(', '),
    }
  ]

  return (<>
    <BaseModal
      onRequestClose={onRequestClose}
      modalClass={styles.userDetailsModal}
      modalCardSize='big'
    >
      <div className={styles.name}>{userData.name}</div>
      <div className={styles.company}>{userData.company}</div>
      <div className={styles.tablesWrapper}>
        {dataLabelMap.map((data, index) => (
          <table className={styles.table} key={index}>
            <tbody>
              {Object.entries(data).map(([label, value]) => (
                <tr key={label}>
                  <th>{label}</th>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
      <Button className={styles.okButton} onClick={onRequestClose}>OK</Button>
    </BaseModal>
  </>)
}

export default UserDetailsModal
