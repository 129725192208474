import React from 'react'

import styles from './Card.module.scss'

interface Props {
  className?: string;
  style?: React.CSSProperties;
  size?: 'small' | 'big';
}

const Card: React.FC<Props> = ({
  className,
  style,
  size = 'big',
  children
}) => {
  return (
    <div style={style} className={[className, styles.card, styles[size]].join(' ')}>
      {children}
    </div>
  )
}

export default Card
