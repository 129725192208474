import React, { useState,useContext } from 'react'
import { FirebaseContext } from '../Firebase'
import TextField from '../TextField'
import Button from '../Button'
import { T, t, TranslationPaths } from '../../i18n/i18n';

interface Props {
  className?: string;
}

const PasswordChange = ({className}: Props) => {
  const [password, setPassword] = useState('')
  const [errorKey, setErrorKey] = useState<TranslationPaths>()
  const [successfullyChanged, setSuccessfullyChanged] = useState(false)

  const firebase = useContext(FirebaseContext)

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const updateResponse = firebase && firebase.doPasswordUpdate(password)
    if(updateResponse) {
      updateResponse
        .then(() => {
          setPassword('')
          setErrorKey(undefined)
          setSuccessfullyChanged(true)
        })
        .catch(error => setErrorKey(`firebase.errors.${error.code}`))
    } else {
      setErrorKey('account.notLoggedIn')
    }
  }

  return (
    <form className={className} onSubmit={onSubmit}>
      <TextField
        type='password'
        autocomplete='new-password'
        label={t('account.newPassword')}
        value={password}
        onChange={setPassword}
      />
      <Button type='submit'><T path='account.changePassword' /></Button>
      {errorKey && <p style={{color: 'red'}}>{<T path={errorKey} />}</p>}
      {successfullyChanged && <p><T path='account.passwordChanged' /></p>}
    </form>
  )
}

export default PasswordChange
