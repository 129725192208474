import React from 'react'
import Legal from './Legal'

const Impressum = () => {
  return (
    <Legal>
      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>Arnold Drachenberg<br />
      Arnold Drachenberg Dental Labor<br />
      K&ouml;hlershohnerstr. 29<br />
      53578 Windhagen</p>

      <h2>Kontakt</h2>
      <p>Telefon: -<br />
      E-Mail: info@drachenberg-dental.de</p>

      <h2>Umsatzsteuer-ID</h2>
      <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
      DE261539484</p>

      <h2>Aufsichtsbeh&ouml;rde</h2>
      <p>Handwerkskammer f&uuml;r Schwaben<br />
      Siebentischstra&szlig;e 52<br />
      86161 Augsburg</p>
      <p><a href="https://www.hwk-schwaben.de/" target="_blank" rel="noopener noreferrer">https://www.hwk-schwaben.de/</a></p>

      <h2>EU-Streitschlichtung</h2>
      <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

      <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
      <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    </Legal>
  )
}

export default Impressum
