const BASE_ROUTES = {
  HOME: '/',
  SIGN_IN: '/login',
  EMAIL_NOT_VERIFIED: '/email-nicht-bestätigt',
  NOT_SUBSCRIBED: '/abonnieren',
  ACCOUNT: '/account',
  PASSWORD_FORGET: '/passwort-vergessen',
  DEV: '/dev',
}

const caBase = '/farbanalyse'
const CA_ROUTES = {
  HOME: caBase + '/',
  WHITEBALANCE: caBase + '/weissabgleich',
  CROP_TOOTH: caBase + '/zahnauswahl',
  COMPARISON: caBase + '/farbvergleich',
  LAYERING: caBase + '/schichtschema',
}

const vfBase = '/virtuelle-anprobe'
const VF_ROUTES = {
  HOME: vfBase + '/',
  PATIENT_WHITEBALANCE: vfBase + '/patient-weissabgleich',
  REPLACEMENT_SELECT: vfBase + '/ersatz',
  REPLACEMENT_WHITEBALANCE: vfBase + '/ersatz-weissabgleich',
  REPLACEMENT_CROP: vfBase + '/ersatz-freistellen',
  PLACING: vfBase + '/platzieren',
  COMPARISON: vfBase + '/vergleichen',
}

export {
  BASE_ROUTES,
  CA_ROUTES,
  VF_ROUTES
}
