import React from 'react'

import styles from './Button.module.scss'

interface Props {
  className?: string;
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  variant?: 'filled' | 'link';
  style?: React.CSSProperties;
}

const Button: React.FC<Props> = ({
  className,
  variant = 'filled',
  ...rest
}) => {
  return (
    <button {...rest} className={[styles.button, styles[variant], className].join(' ')}/>
  )
}

export default Button
