import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { BASE_ROUTES, CA_ROUTES, VF_ROUTES } from '../../constants/routes'
import { T } from '../../i18n/i18n'
import AccountIcon from '../../assets/icons/account.svg'
import LogoMark from '../../assets/logo-mark.svg'
import Card from '../Card'
import styles from './Navigation.module.scss'
import { UserContext } from '../Session'
import LanguageSelection from './LanguageSelection'

export interface NavigationProps {
  homeLink?: string;
  isWebsite?: boolean;
  links?: {
    to: string;
    text: string;
    external?: boolean;
  }[];
}

const Navigation = ({
  homeLink,
  isWebsite,
  links
}: NavigationProps) => {
  const user = useContext(UserContext)
  const { pathname } = useLocation()
  const isCA = pathname.startsWith(CA_ROUTES.HOME)
  const isVF = pathname.startsWith(VF_ROUTES.HOME)

  const disabledClass = (route: string) => {
    const routeOrder = Object.values({...CA_ROUTES, ...VF_ROUTES})
    const currentIndex = routeOrder.indexOf(pathname)
    const routeIndex = routeOrder.indexOf(route)

    return currentIndex <= routeIndex ? styles.disabled : undefined
  }

  return (
    <Card className={styles.navigation} size='small' style={{padding: 0}}>
      <div className={styles.mainMenu}>
        <NavLink to={homeLink || '/'} className={styles.logoMark}>
          <img src={LogoMark} alt='Dragonshade Bildmarke' />
        </NavLink>
        <nav>
          {
            (
              links && links.map(link => link.external ?
                  <a key={link.to} href={link.to} target='_blank' rel='noopener noreferrer'>{link.text}</a>
                :
                  <NavLink key={link.to} activeClassName={styles.activeLink} to={link.to}>{link.text}</NavLink>
              )
            ) || (
              user && user.subscriptionStatus === 'subscribed' ? <>
                <NavLink activeClassName={styles.activeLink} to={CA_ROUTES.HOME}><T path='colorAnalysis.title' /></NavLink>
                <NavLink activeClassName={styles.activeLink} to={VF_ROUTES.HOME}><T path='virtualFitment.title' /></NavLink>
              </>
            :
              user?.emailVerified ?
                <NavLink activeClassName={styles.activeLink} to={BASE_ROUTES.NOT_SUBSCRIBED}><T path='account.subscribe' /></NavLink>
              :
                (user && <NavLink activeClassName={styles.activeLink} to={BASE_ROUTES.EMAIL_NOT_VERIFIED}><T path='account.confirmEmail' /></NavLink>)
            )
          }
        </nav>
        {isWebsite && <div className={styles.spacer} />}
        <div className={styles.langAndAccount}>
          <LanguageSelection />

          {user && <NavLink to={BASE_ROUTES.ACCOUNT} className={styles.accountBtn}>
            <img src={AccountIcon} alt='' />
          </NavLink>}
        </div>
      </div>
      {!isWebsite && (isCA || isVF) && <div className={styles.subMenu}>
        {isCA && <>
          <NavLink className={disabledClass(CA_ROUTES.HOME)} activeClassName={styles.activeLink} to={CA_ROUTES.HOME} exact><T path='colorAnalysis.start' /></NavLink>
          <NavLink className={disabledClass(CA_ROUTES.WHITEBALANCE)} activeClassName={styles.activeLink} to={CA_ROUTES.WHITEBALANCE}><T path='colorAnalysis.whitebalance' /></NavLink>
          <NavLink className={disabledClass(CA_ROUTES.CROP_TOOTH)} activeClassName={styles.activeLink} to={CA_ROUTES.CROP_TOOTH}><T path='colorAnalysis.cropTooth' /></NavLink>
          <NavLink className={disabledClass(CA_ROUTES.COMPARISON)} activeClassName={styles.activeLink} to={CA_ROUTES.COMPARISON}><T path='colorAnalysis.colorComparison' /></NavLink>
          <NavLink className={disabledClass(CA_ROUTES.LAYERING)} activeClassName={styles.activeLink} to={CA_ROUTES.LAYERING}><T path='colorAnalysis.layering' /></NavLink>
        </>}
        {isVF && <>
          <NavLink className={disabledClass(VF_ROUTES.HOME)} activeClassName={styles.activeLink} to={VF_ROUTES.HOME} exact><T path='virtualFitment.start' /></NavLink>
          <NavLink className={disabledClass(VF_ROUTES.PATIENT_WHITEBALANCE)} activeClassName={styles.activeLink} to={VF_ROUTES.PATIENT_WHITEBALANCE} exact><T path='virtualFitment.patientWhitebalance' /></NavLink>
          <NavLink className={disabledClass(VF_ROUTES.REPLACEMENT_SELECT)} activeClassName={styles.activeLink} to={VF_ROUTES.REPLACEMENT_SELECT} exact><T path='virtualFitment.replacementSelect' /></NavLink>
          <NavLink className={disabledClass(VF_ROUTES.REPLACEMENT_WHITEBALANCE)} activeClassName={styles.activeLink} to={VF_ROUTES.REPLACEMENT_WHITEBALANCE} exact><T path='virtualFitment.replacementWhitebalance' /></NavLink>
          <NavLink className={disabledClass(VF_ROUTES.REPLACEMENT_CROP)} activeClassName={styles.activeLink} to={VF_ROUTES.REPLACEMENT_CROP} exact><T path='virtualFitment.replacementCrop' /></NavLink>
          <NavLink className={disabledClass(VF_ROUTES.PLACING)} activeClassName={styles.activeLink} to={VF_ROUTES.PLACING} exact><T path='virtualFitment.placing' /></NavLink>
          <NavLink className={disabledClass(VF_ROUTES.COMPARISON)} activeClassName={styles.activeLink} to={VF_ROUTES.COMPARISON} exact><T path='virtualFitment.comparison' /></NavLink>
        </>}
      </div>}
    </Card>
  )
}

export default Navigation
