import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../Session'
import { FirebaseContext } from '../../Firebase'

import SignOutButton from '../../SignOutButton'
import PasswordChange from '../../PasswordChange'
import Button from '../../Button'
import Card from '../../Card'
import ReleaseNotesModal from '../../Modals/ReleaseNotesModal'
import infoIcon from '../../../assets/icons/info.svg'
import { Link } from 'react-router-dom'
import { BASE_ROUTES } from '../../../constants/routes'
import { IS_DEV, USING_APP_VERSION } from '../../../constants/app'
import { T, t } from '../../../i18n/i18n'
import Tabs from '../../Tabs'
import Spinner from '../../Spinner'
import BillingAddressTable from './BillingAddressTable'
import styles from './Account.module.scss'

const Account = () => {
  const user = useContext(UserContext)
  const firebase = useContext(FirebaseContext)
  const [cancelMessage, setCancelMessage] = useState<React.ReactNode>()
  const [showReleaseNotesModal, setShowReleaseNotesModal] = useState(false)
  const [currentTab, setCurrentTab] = useState('account')

  const cancelSubscribtion = async () => {
    if (window.confirm(t('account.confirmCancelSubscription'))) {
      if (firebase) {
        const result = await firebase.cancelSubscription()
        if (result.success) {
          if (result.periodEnd) {
            setCancelMessage(<T
              path='account.yourSubscriptionWillBeCanceledOn'
              params={[new Date(result.periodEnd).toLocaleDateString()]}
            />)
          } else {
            setCancelMessage(<T path='account.yourSubscriptionWillBeCanceledSoon' />)
          }
        } else {
          setCancelMessage(<T path='account.errorCancelingSubscription' />)
        }
      }
    }
  }

  const [invoices, setInvoices] = useState<FirestoreInvoiceWithId[]>()

  // Load invoices
  useEffect(() => {
    if (firebase && user && currentTab === 'invoices') {
      firebase
        .queryCollection('invoices', [
          { property: 'userId', operator: '==', value: user.uid },
        ])
        .then((newInvoices) => {
          setInvoices(newInvoices)
        })
    }
  }, [firebase, user, currentTab])

  const downloadInvoice = async (invoice: FirestoreInvoiceWithId) => {
    if (firebase && invoices) {
      try {
        const url = await firebase.getUrlForStorageObject(`invoices/${invoice.id}.pdf`)

        const response = await fetch(url)
        const blob = await response.blob()

        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${invoice.invoiceNumber}.pdf`

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (e) {
        console.error(e);
      }
    }
  }

  const subscriptionStatusLabel = user && <T path={`account.subscriptionStatusLabels.${user.subscriptionStatus}`} />
  const paymentMethodLabel = user?.paymentMethod && <T path={`account.paymentMethodLabels.${user.paymentMethod}`} />

  return (<>
    <Card className={styles.account}>
      <Tabs
        tabs={[
          {
            title: t('account.account'),
            value: 'account',
          }, {
            title: t('account.invoices'),
            value: 'invoices',
          }
        ]}
        currentTab={currentTab}
        onChange={setCurrentTab}
        className={styles.tabs}
      />

      {currentTab === 'account' && (
        <>
          <h2><T path='account.profile' /></h2>
          <table className={styles.profileTable}>
            <tbody>
              <tr>
                <td><T path='account.name' /></td>
                <td>{user?.name}</td>
              </tr>
              <tr>
                <td><T path='account.email' /></td>
                <td>{user?.email}</td>
              </tr>
              <tr>
                <td><T path='account.subscriptionStatus' /></td>
                <td>{subscriptionStatusLabel}</td>
              </tr>
              {user?.subscriptionStatus === 'subscribed' && paymentMethodLabel && <tr>
                  <td><T path='account.paymentMethod' /></td>
                  <td>
                    {paymentMethodLabel}
                    {user?.paymentMethod === 'voucher' && <>
                      {' '}(<T path='account.validUntil' params={[user?.voucherValidUntil?.toDate().toLocaleDateString()]} />)
                    </>}
                  </td>
                </tr>}
              {user?.subscriptionCancelDate && (
                <tr>
                  <td><T path='account.subscriptionCancelDate' /></td>
                  <td>{user?.subscriptionCancelDate?.toDate().toLocaleDateString()}</td>
                </tr>
              )}
            </tbody>
          </table>

          {firebase && user?.subscriptionStatus === 'subscribed' && !user.subscriptionCancelDate && (
            <Button
              onClick={cancelSubscribtion}
              variant='link'
              style={{ color: 'red' }}
            >
              <T path='account.cancelSubscription' />
            </Button>
          )}

          <h2><T path='auth.billingAddress' /></h2>

          <BillingAddressTable />

          {cancelMessage && <p>{cancelMessage}</p>}
          <h2><T path='account.password' /></h2>
          <PasswordChange className={styles.passwordChangeForm} />
          <SignOutButton className={styles.logoutButton} />
          {USING_APP_VERSION && (<>
            <span className={styles.appVersion}>
              <div className={styles.version}>
                v{USING_APP_VERSION} {IS_DEV && <Link to={BASE_ROUTES.DEV}>DEV</Link>}
              </div>
              <img
                src={infoIcon}
                onClick={() => setShowReleaseNotesModal(true)}
                className={styles.releaseInfoIcon}
                title={t('account.showReleaseNotes')}
                alt=''
              />
            </span>
          </>)}
        </>
      )}

      {currentTab === 'invoices' && (
        invoices ? (
          <>
            <table className={styles.invoicesTable}>
              <tbody>
                <tr>
                  <th><T path='account.invoiceNumber' /></th>
                  <th><T path='account.date' /></th>
                  <th></th>
                </tr>
                {invoices.sort((a, b) => b.created.toDate().getTime() - a.created.toDate().getTime()).map(invoice => (
                  <tr key={invoice.invoiceNumber}>
                    <td>{invoice.invoiceNumber}</td>
                    <td>{invoice.created.toDate().toLocaleDateString()}</td>
                    <td>
                      <Button
                        variant='link'
                        onClick={() => downloadInvoice(invoice)}
                      >
                        <T path='general.download' />
                      </Button>
                    </td>
                  </tr>
                ))}

                {invoices.length === 0 && (
                  <tr>
                    <td colSpan={3}><T path='account.noInvoices' /></td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        ) : (
          <Spinner />
        )
      )}

    </Card>

    {showReleaseNotesModal && <ReleaseNotesModal onRequestClose={() => setShowReleaseNotesModal(false)}/>}
  </>)
}

export default Account
